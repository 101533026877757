import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subject, Subscription } from "rxjs";
import { slideInOutAnimation } from "src/app/Animations";
import { emailValidator } from "src/app/Directive/email-validator.directive";
import { ApiCallService } from "src/app/Services/api-call.service";
import { DataShareService } from "src/app/Services/data-share.service";
import { GlobalService } from "src/app/Services/global.service";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
  animations: [slideInOutAnimation],
  host: { "[@slideInOutAnimation]": "" },
})
export class ForgetPasswordComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private subscription: Subscription = new Subscription();

  interval: string = "";
  forgotPasswordForm!: FormGroup;
  userEmail: string = "";
  fetchEmail: string = "";
  isLoading: boolean = false;
  btnLoader: boolean = false;
  getUserEmail: any = "";
  constructor(
    public Global: GlobalService,
    private callMethod: ApiCallService,
    public router: Router,
    public toaster: MessageService,
    public dataShareService: DataShareService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", [
        Validators.required,
        Validators.minLength(3),
        emailValidator(),
      ]),
    });
    this.route.queryParams.subscribe((params) => {
      const encryptedResetUserEmail = params["resetEmail"];
      const decryptedResetUserEmail = this.Global.decryptData(
        encryptedResetUserEmail
      );
      console.log("Decrypted User Email", decryptedResetUserEmail);
      this.forgotPasswordForm.patchValue({
        email: decryptedResetUserEmail,
      });
      this.forgotPasswordForm.updateValueAndValidity();
    });
  }

  get email() {
    return this.forgotPasswordForm.get("email")!;
  }

  validate() {
    if (this.forgotPasswordForm.invalid) {
      for (const control of Object.keys(this.forgotPasswordForm.controls)) {
        this.forgotPasswordForm.controls[control].markAsTouched();
      }
      return;
    } else {
      this.forgotPassword();
    }
  }

  forgotPassword() {
    this.btnLoader = true;
    let reqObj = {
      email: this.forgotPasswordForm.value.email,
    };

    this.Global.userDetails.email = reqObj.email;
    let merge =
      this.forgotPasswordForm.value.email +
      this.Global.companyDetails.companyId;
    this.Global.setHeaderEncryptData(merge);

    this.callMethod.forgotPassword(reqObj).subscribe((result: any) => {
      console.log(
        "********************************** forgotPassword() **********************************",
        result
      );
      if (result.status) {
        this.toaster.add({
          severity: "success",
          summary: "Great !",
          detail: "OTP Successfully Send To Email",
        });
        this.Global.setLocalStorage({
          key: "userId",
          value: result.data.userId,
        });
        this.Global.userDetails.userId = result.data.userId;
        this.shareUserId(result.data.userId);
        this.isLoading = false;
        setTimeout(() => {
          this.btnLoader = false;
          const encryptedUserEmail = this.Global.encryptData(
            this.forgotPasswordForm.value.email.trim()
          );
          this.router.navigate(["/reset-password"], {
            queryParams: { resetEmail: encryptedUserEmail },
          });
        }, 1000);
      } else {
        if (result.statusCode == 601) {
          this.toaster.add({
            severity: "warn",
            summary: "Warning!",
            detail: result.message,
          });
          setTimeout(() => {
            this.btnLoader = false;
            const encryptedUserEmail = this.Global.encryptData(
              this.forgotPasswordForm.value.email.trim()
            );
            this.router.navigate(["/reset-password"], {
              queryParams: { resetEmail: encryptedUserEmail },
            });
          }, 1000);
        }
        else {
          this.toaster.add({
            severity: "error",
            summary: "Error !",
            detail: result.message,
          });
          this.btnLoader = false;
        }
      }
    });
  }

  shareUserId(data: any) {
    console.log(" getUserId :::::::: ", data);
    this.dataShareService.shareData({ function: "getUserId", data: data });
    this.Global.setLocalStorage({ key: "userId", value: data });
  }
}
