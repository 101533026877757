import { Injectable } from '@angular/core';
import { GlobalService } from '../Services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardServiceService {

  constructor(private Global: GlobalService) { }
  getToken()
  {  
    return this.Global.getLocalStorage({key: 'JwtToken'}); 
    }  
}
