
    <div class="main_404_page">
        <!-- <h1>404 Error Page</h1> -->
        <section class="error-container">
            <span class="four"><span class="screen-reader-text">4</span></span>
            <span class="zero"><span class="screen-reader-text">0</span></span>
            <span class="four"><span class="screen-reader-text">4</span></span>
        </section>
        <p class="zoom-area"><b>Oops!</b> It looks like you're lost...
            That's a trouble? </p>
        <div class="link-container">
          <div  routerLink="/login" class="more-link cursor-pointer">Visit the original web panel</div>
        </div>
    </div>
