import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from "../Interface/taskListInterface"

@Injectable({
  providedIn: 'root',
})

export class ApiCallService {
  constructor(public http: HttpClient) { }

  //********************  TEAM  ********************
  deleteTeam(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/delete', opts);
  }
  updateTeam(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/edit', opts);
  }
  createTeam(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/create', opts);
  }
  getAllTeams(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/list', opts);
  }
  getSingleTeams(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/single', opts);
  }
  getMultipleTeamsById(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/get/multiple/team/driver', opts);
  }


  //********************  HUB  ********************
  getAllHubs(opts: any): Observable<any[]> {
    return this.http.post<any[]>('hub/list', opts);
  }
  getSingleHub(opts: any): Observable<any[]> {
    return this.http.post<any[]>('hub/single', opts);
  }
  createHub(opts: any): Observable<any[]> {
    return this.http.post<any[]>('hub/create', opts);
  }
  updateHub(opts: any): Observable<any[]> {
    return this.http.post<any[]>('hub/edit', opts);
  }
  deleteHub(opts: any): Observable<any[]> {
    return this.http.post<any[]>('hub/delete', opts);
  }

  //********************  DISPATCHER  ********************
  getAllDispatchers(opts: any): Observable<any[]> {
    return this.http.post<any[]>('dispatcher/list', opts);
  }
  // getSingleDispatcher(opts: any): Observable<any[]> {
  //   return this.http.post<any[]>('/dispatcher/single', opts);
  // }
  createDispatcher(opts: any): Observable<any[]> {
    return this.http.post<any[]>('dispatcher/create', opts);
  }
  updateDispatcher(opts: any): Observable<any[]> {
    return this.http.post<any[]>('dispatcher/edit', opts);
  }
  deleteDispatcher(opts: any): Observable<any[]> {
    return this.http.post<any[]>('dispatcher/delete', opts);
  }

  //********************  ADMIN  ********************
  getAllAdmin(opts: any): Observable<any[]> {
    return this.http.post<any[]>('admin/list', opts);
  }
  // getSingleAdmin(opts: any): Observable<any[]> {
  //   return this.http.post<any[]>('/admin/single', opts);
  // }
  createAdmin(opts: any): Observable<any[]> {
    return this.http.post<any[]>('admin/create', opts);
  }
  updateAdmin(opts: any): Observable<any[]> {
    return this.http.post<any[]>('admin/edit', opts);
  }
  deleteAdmin(opts: any): Observable<any[]> {
    return this.http.post<any[]>('admin/delete', opts);
  }

  //********************  DRIVER  ********************
  getAllDrivers(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/list', opts);
  }
  createDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/create', opts);
  }
  getSingleDrivers(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/single', opts);
  }
  editDrivers(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/edit', opts);
  }
  deleteDrivers(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/delete', opts);
  }
  driverVerify(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/verify', opts);
  }
  driverOffline(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/switch', opts);
  }
  getDriverByName(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/get/drivers', opts);
  }

  getDriverBankDetails(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/get/bank/details', opts);
  }

  updateDriverBankDetails(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/update/bank/details', opts);
  }
  // Driver COD transaction List 
  driverCodTransactionList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/cod/transaction/list', opts);
  }
  driverCodTransactionSettlement(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/cod/settlement', opts);
  }
  driverTaskSequencesShuffle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/sequences/shuffle', opts);
  }

  driverJobSequencesShuffle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('job/sequence/change', opts);
  }
  driverMapList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/list/map', opts);
  }

  getDriverAlert(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/alerts/get', opts);
  }

  getDriverBreakAlert(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/break/alert/get', opts);
  }

  createBulkDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/bulk/upload', opts);
  }


  //********************  NON - AUTH  ********************
  getCompanyDetails(opts: any): Observable<any[]> {
    return this.http.post<any[]>('company/details/get', opts);
  }
  signUpCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/registration', opts);
  }
  editUserCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/edit', opts);
  }
  signInCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/login', opts);
  }
  forgotPassword(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/forgot/password', opts);
  }
  sendOTP(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/verify/otp', opts);
  }
  resetPassword(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/change/password', opts);
  }

  //********************  TASK  ********************
  createTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/create', opts);
  }
  getAllTasks(opts: any): Observable<any[]> {
    return this.http.post<Task[]>('task/all', opts);
  }
  getSingleTaskTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/single', opts);
  }
  editTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/edit', opts);
  }
  deleteTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/delete', opts);
  }
  cancelTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/cancel', opts);
  }
  planningTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/task/status/planning', opts);
  }
  getTaskActivityLogs(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/activity', opts);
  }
  taskStatusChange(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/status/change', opts);
  }
  getTaskDistance(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/task/distance/get', opts);
  }

  forceDeliver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/task/force/deliver', opts);
  }
  taskShuffleSeqActivitiesList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/task/sequence/activity/get', opts);
  }


  //********************  ELASTIC SEARCH  ********************
  getAutoCompleteAddress(opts: any): Observable<any[]> {
    const endPoint = "address/get";
    return this.http.post<any[]>(endPoint, opts);
  }
  setAutoCompleteAddress(opts: any): Observable<any[]> {
    const endPoint = "address/set";
    return this.http.post<any[]>(endPoint, opts);
  }

  //********************  Dashboard Call   //********************
  dashboard(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/dashboard', opts);
  }

  //********************  ApiKeys  ********************
  listAllApiKey(opts: any): Observable<any[]> {
    return this.http.post<any[]>('apiKey/list', opts);
  }
  createApiKey(opts: any): Observable<any[]> {
    return this.http.post<any[]>('apiKey/create', opts);
  }
  // getSingleApiKey(opts: any): Observable<any[]> {
  //   return this.http.post<any[]>('apiKey/single', opts);
  // }
  editApiKey(opts: any): Observable<any[]> {
    return this.http.post<any[]>('apiKey/edit', opts);
  }
  deleteApiKey(opts: any): Observable<any[]> {
    return this.http.post<any[]>('apiKey/delete', opts);
  }

  //********************  Webhook  ********************

  updateWebhook(opts: any): Observable<any[]> {
    return this.http.post<any[]>('webhook/edit', opts);
  }
  listWebhook(opts: any): Observable<any[]> {
    return this.http.post<any[]>('webhook/single', opts);
  }

  //********************  General Setting  ********************

  generalSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('general/setting/edit', opts);
  }

  emailSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('email/format/edit', opts);
  }

  getEmailSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('email/format/get', opts);
  }

  messageOrWhatsappSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('message/format/edit', opts);
  }

  getMessageOrWhatsappSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('message/format/get', opts);
  }

  notificationSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('notification/format/edit', opts);
  }

  getNotificationSetting(opts: any): Observable<any[]> {
    return this.http.post<any[]>('notification/format/get', opts);
  }

  //********************  Vehicle Data  ********************

  getAllCarType(opts: any): Observable<any[]> {
    return this.http.post<any[]>('car/types', opts);
  }
  getVehicleBrands(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/brands', opts);
  }
  getVehicleModels(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/models', opts);
  }
  getSearchDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/get/drivers', opts);
  }
  getCreateVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/create', opts);
  }

  getAllVehicles(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/list', opts);
  }

  manualAssignDriverVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/assign/driver', opts);
  }
  editDriverVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/edit', opts);
  }
  deleteDriverVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/delete', opts);
  }
  driverVehicleStatus(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/change/status', opts);
  }

  unassignedDriverVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/unassign/driver', opts);
  }
  checkDriverVehicle(opts: any): Observable<any[]> {
    return this.http.post<any[]>('vehicle/license/check', opts);
  }

  //******************** Manual Assignment Driver  ********************

  manualAssignDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/assign', opts);
  }

  //******************** Team wise Assignment Driver  ********************

  getDriversByTeamIDs(opts: any): Observable<any[]> {
    return this.http.post<any[]>('team/drivers', opts);
  }

  //******************** Auto Assignment to nearest Driver  ********************

  autoAssignNearestDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/assign/nearest/driver', opts);
  }

  //********************  Zones  ********************


  getAllHubZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/getHubZone', opts);
  }

  getAllServiceZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/getServiceZone', opts);
  }

  zoneAssignToDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/assign', opts);
  }

  createZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/create', opts);

  }
  updateZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/update', opts);
  }
  deleteZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>('zone/delete', opts);

  }

  //********************  Driver Shift  ********************
  createDriverShift(opts: any): Observable<any[]> {
    return this.http.post<any[]>('shifts/create', opts);
  }
  getAllDriverShift(opts: any): Observable<any[]> {
    return this.http.post<any[]>('shifts/list', opts);
  }
  updateDriverShift(opts: any): Observable<any[]> {
    return this.http.post<any[]>('shifts/edit', opts);
  }
  shiftAssignToDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/shift/create', opts);
  }
  getSingleDriverShift(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/get/single/shift', opts);
  }

  deleteDriverShift(opts: any): Observable<any[]> {
    return this.http.post<any[]>('shifts/delete', opts);
  }

  getDriverShiftData(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/shift/list', opts);
  }
  getDriverDetails(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/details', opts);
  }

  //********************  Route Optimize  ******************** 
  routeOptimize(opts: any): Observable<any[]> {
    return this.http.post<any[]>('route/optimize', opts);
  }
  getDistanceAndDurationFromOSRM(opts: any): Observable<any[]> {
    return this.http.post<any[]>('get/estimated/duration', opts);
  }
  geoCoding(opts: any): Observable<any[]> {
    return this.http.post<any[]>('get/geloky/address', opts);
  }
  unAssignPlan(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/tour/unassigned/driver', opts);
  }
  updateTourAssignments(opts: any): Observable<any[]> {
    return this.http.post<any[]>('tour/recalculate/route', opts);
  }

  //********************  Manage Restaurant  ********************
  createBranch(opts: any): Observable<any[]> {
    return this.http.post<any[]>('branch/create', opts);
  }

  editBranchApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('branch/edit', opts);
  }

  deleteBranchApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('branch/delete', opts);
  }

  getAllBranch(opts: any): Observable<any[]> {
    return this.http.post<any[]>('branch/list', opts);
  }

  //********************  Manage SurgeZone  ********************
  createSurgeZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('surge/zone/create', opts);
  }
  updateSurgeZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('surge/zone/update', opts);
  }
  deleteSurgeZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('surge/zone/delete', opts);
  }
  getAllSurgeZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('surge/zone/list', opts);
  }
  // Area Zone Api
  getAllAreaZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('area/zone/list', opts);
  }

  getAllAreaZoneDashboardApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/area/filter/list', opts);
  }

  createAreaZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('area/zone/create', opts);
  }

  updateAreaZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('area/zone/update', opts);
  }

  searchZone(opts: any): Observable<any[]> {
    return this.http.post<any[]>("zone/search/coords", opts);
  }
  deleteAreaZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('area/zone/delete', opts);
  }
  multipleInsertAreaZoneApiCall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('area/multiple/insert', opts);
  }

  // unassignedTaskList by hina tank
  unassignedTaskList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/unassigned', opts);
  }

  //********************  Plan  ********************
  planCreate(opts: any): Observable<any[]> {
    return this.http.post<any[]>('plan/create', opts);
  }
  getSinglePlan(opts: any): Observable<any[]> {
    return this.http.post<any[]>('plan/detail', opts);
  }
  getAllPlan(opts: any): Observable<any[]> {
    return this.http.post<any[]>('plan/list', opts);
  }

  planDraft(opts: any): Observable<any[]> {
    return this.http.post<any[]>('plan/draft', opts);
  }

  getTempPlaning(opts: any): Observable<any[]> {
    return this.http.post<any[]>('/task/temp/planning/list', opts);
  }

  deletePlanAPICall(opts: any): Observable<any[]> {
    return this.http.post<any[]>('plan/delete', opts);
  }




  //********************  Plan  ******************** 
  tourWiseJobList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('tour/jobs', opts);
  }

  tourAssignToDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('tour/assign/driver', opts);
  }

  tourUnassignToDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('tour/unassigned/driver', opts);
  }

  newJobAssignInTour(opts: any): Observable<any[]> {
    return this.http.post<any[]>('tour/assign/new/job', opts);
  }

  //********************  JOBS  ******************** hina tank

  deleteJobInJonList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('job/delete', opts);
  }

  // ********************************** Completed Jobs & Task **********************************

  getCompletedJobAndTaskCount(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/completed/job/count', opts);
  }

  getCompletedJobAndTaskList(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/completed/job/list', opts);
  }

  // ********************************** Analytics ********************************** 

  taskAnalytics(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/analytics', opts);
  }

  driverAnalytics(opts: any): Observable<any[]> {
    return this.http.post<any[]>('driver/analytics', opts);
  }

  driverShiftSummary(opts: any): Observable<any[]> {
    return this.http.post<any[]>('drivers/shift/summary/get', opts);
  }



  // ********************************** Task Paci & Area Data ********************************** 

  getPaciData(opts: any): Observable<any[]> {
    return this.http.post<any[]>('paci/search', opts);
  }

  getAreaNameByCoords(opts: any): Observable<any[]> {
    return this.http.post<any[]>('task/area/get', opts);
  }

  // ********************************** Chat ********************************** 

  sendMessageToDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/send/message/driver', opts);
  }

  getMessageForTask(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/chat/get', opts);
  }

  getAllDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/get/chat/list', opts);
  }

  chatRead(opts: any): Observable<any[]> {
    return this.http.post<any[]>('chat/read', opts);
  }

  searchDriver(opts: any): Observable<any[]> {
    return this.http.post<any[]>('user/chat/search/driver', opts);
  }


}
