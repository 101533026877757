import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appAutofocusValidation]'
})
export class AutofocusValidationDirective {

	constructor(private el: ElementRef) { }

	@HostListener('submit')
	onFormSubmit() {
		const invalidInputControl = this.el.nativeElement.querySelector(
			'.ng-invalid'
		);
		console.log('****** AutofocusValidationDirective invalidInputControl ******', invalidInputControl);
		if (invalidInputControl) {
			console.log('****** AutofocusValidationDirective invalidInputControl.focus() ******', invalidInputControl.focus());
			invalidInputControl.focus();
		}
	}

}
