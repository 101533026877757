import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { MenuItem, MessageService } from 'primeng/api';
import { fadeInAnimation } from 'src/app/Animations';
import { ApiCallService } from 'src/app/Services/api-call.service';
import { GlobalService } from 'src/app/Services/global.service';
import { emailValidator } from '../../Directive/email-validator.directive';
 const timezone_country = require('../../../assets/country-timezone.js');


interface IUser {
  name: string;
  companyName: string;
  email: string;
  phoneNumber: number;
  password: string;
  countryLists: any;
  industriesList: any;
  monthlyVolumeList: any;
}

interface IUserCountry {
  countryLists: any;
  industriesList: any;
  monthlyVolumeList: any;
  timeZone: string;
}
 

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' },
})
export class RegisterComponent implements OnInit {
  timeZoneList: any = [];
  personalForm!: FormGroup;
  personalCountryForm!: FormGroup;

  separateDialCode = false;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  selectedCountryFlag: any

  user: IUser = {} as IUser;
  userCountry: IUserCountry = {} as IUserCountry;

  constructor(
    public toaster: MessageService,
    private callMethod: ApiCallService,
    public Global: GlobalService,
    public router: Router
  ) {}

  activeIndex = 0;
  page1 = true;
  page2 = false;
  page3 = false;
  selectedCountry: any;
  selectedTimeZone: any;
  selectedIndustries: any;
  selectedMonthlyVolume: any;
  monthlyVolume: any;
  countryList: any[] = [];
  industries: any[] = [];
  isValidate = false;
  pageTitle = '';
  flag: any = [];

  ngOnInit(): void {
    this.selectedCountryFlag = CountryISO.Kuwait;
    this.countryList = Object.values(timezone_country.getAllCountries());
    this.pageTitle = this.Global.getLocalStorage({ key: 'pageTitle' });

    this.personalForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
      ]),
      companyName: new FormControl('', [
        Validators.required,
        Validators.maxLength(250),
      ]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
        ),
      ]),
    });

    this.personalCountryForm = new FormGroup({
      countryLists: new FormControl('', [Validators.required]),
      industriesList: new FormControl('', [Validators.required]),
      monthlyVolumeList: new FormControl('', [Validators.required]),
      timeZone: new FormControl('', [Validators.required]),
    });

    this.industries = [
      { name: 'Beverage' },
      { name: 'Building / Construction' },
      { name: 'Prepared Meals' },
      { name: 'Restaurant' },
      { name: 'Restaurant Delivery Service' },
      { name: 'Retail / eCommerce' },
      { name: 'Shipping / Postal' },
      { name: 'Third Party Logistics' },
      { name: 'Waste Management / Recycling' }, 
      { name: 'Concierge' },
      { name: 'Courier' },
      { name: 'Desserts / Novelties' },
      { name: 'Grocery / Produce' },
      { name: 'Laundry / Drycleaning' },
      { name: 'Moving / Storage' },
      { name: 'Pharmacy / Medical' },
      { name: 'Other' },
    ];

    this.monthlyVolume = [
      { name: '0 - 100' },
      { name: '101 - 2,500' },
      { name: '2,501 - 5,000' },
      { name: '5,001 - 12,500' },
      { name: '12,501 - 25,000' },
      { name: '25,001 - 50,000' },
      { name: '50,000+' },
    ];
  }

  get name() {
    return this.personalForm.get('name')!;
  }
  get companyName() {
    return this.personalForm.get('companyName')!;
  }
  get email() {
    return this.personalForm.get('email')!;
  }
  get phoneNumber() {
    return this.personalForm.get('phoneNumber')!;
  }
  get password() {
    return this.personalForm.get('password')!;
  }
  get industriesList() {
    return this.personalCountryForm.get('industriesList')!;
  }
  get countryLists() {
    return this.personalCountryForm.get('countryLists')!;
  }
  get monthlyVolumeList() {
    return this.personalCountryForm.get('monthlyVolumeList')!;
  }
  get timeZone() {
    return this.personalCountryForm.get('timeZone')!;
  }

  validate(formName: string) {
    if (formName === 'personalForm') {
      if (this.personalForm.invalid) {
        this.isValidate = true;
        Object.values(this.personalForm.controls).forEach((control) =>
          control.markAsTouched()
        );
        return;
      } else {
        this.signUpCall();
      }
    }

    if (formName === 'countryData') {
      if (this.personalCountryForm.invalid) {
        Object.values(this.personalCountryForm.controls).forEach((control) =>
          control.markAsTouched()
        );
        return;
      } else {
        this.editUserCall();
      }
    }
  }

  loginBtn = false;
  signUpCall() {
    this.Global.getLocalStorage({ key: 'JwtToken' });
    const timeZone = timezone_country.getTimezonesForCountry(
      this.personalForm.value.phoneNumber.countryCode
    );

    this.user = this.personalForm.value;
    const userPhoneNumber = this.Global.getUserPhoneNumber(
      this.user.phoneNumber
    );
    this.loginBtn = true;
    const reqObj = {
      fullName: this.user.name,
      companyName: this.user.companyName,
      email: this.user.email,
      phone: userPhoneNumber,
      password: this.user.password,
      timezone: timeZone[0].name,
    };

    this.Global.setHeaderEncryptData(
      this.user.email + userPhoneNumber + this.Global.companyDetails.companyId
    );
    this.callMethod.signUpCall(reqObj).subscribe((result: any) => {
      if (result.status) {
        this.Global.setLocalStorage({
          key: 'JwtToken',
          value: result.token,
        });
        this.Global.setLocalStorage({
          key: 'userId',
          value: result.data.userId,
        });
        this.Global.setLocalStorage({
          key: 'type',
          value: result.data.type,
        });
        this.activeIndex = 1;
        this.page1 = false;
        this.page2 = true;
      } else {
        this.toaster.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
      }
      this.loginBtn = false;
    });
  }
   registerCountryCode: any[] = []

  editUserCall() {
    this.user = this.personalForm.value;
    this.loginBtn = true;
    this.userCountry = this.personalCountryForm.value;

    const reqObj = {
      industryType: this.userCountry.industriesList.name,
      industryMonthlyVolume: this.userCountry.monthlyVolumeList.name,
      timezone: this.personalCountryForm.value.timeZone.name,
      userId:
        this.Global.getLocalStorage({ key: 'userId' }) ||
        this.Global.userDetails.userId,
      currency: this.registerCountryCode[0].currency,
      country: this.registerCountryCode[0].name,
    };

    this.Global.setHeaderEncryptData(
      reqObj.userId + this.Global.companyDetails.companyId
    );
    this.callMethod.editUserCall(reqObj).subscribe((result: any) => {
      if (result.status) {
        
        this.Global.setLocalStorage({
          key: 'JwtToken',
          value: result.token,
        });
        this.Global.setLocalStorage({
          key: 'type',
          value: result.data.type,
        });
        this.Global.userDetails.JwtToken = result.token
        this.Global.userDetails.fullName = result.data.fullName
        this.Global.userDetails.parentUser = result.data.parentUser
        this.Global.settingInfo.timeZone = result.data.timezone;
        this.Global.userDetails.phone = result.data.phone
        this.Global.userDetails.userCompanyName = result.data.companyName
        this.Global.userDetails.email = result.data.email
        this.Global.userDetails.type = result.data.type
        
        this.router.navigate(['dashboard/manage']);
      } else {
        this.toaster.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
      }
      this.loginBtn = false;
    });
  }

  selectedCountries(selectedCountry: any) {
    this.registerCountryCode = this.Global.countryCodeJson.filter(
      (obj: any) => obj.name === selectedCountry.name
    );
    this.timeZoneList = timezone_country.getTimezonesForCountry(
      selectedCountry.id
    );
  }

  nextPage(isActivePage: string) {
    if (isActivePage === 'page_1') {
      this.activeIndex = 1;
      this.page1 = false;
      this.page2 = true;
    }
    if (isActivePage === 'page_2') {
      this.activeIndex = 0;
      this.page1 = true;
      this.page2 = false;
    }
  }
}



















// import { Component, OnInit } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
// import { MenuItem, MessageService } from 'primeng/api';
// import { fadeInAnimation } from 'src/app/Animations';
// import { ApiCallService } from 'src/app/Services/api-call.service';
// import { GlobalService } from 'src/app/Services/global.service';
// import { emailValidator } from '../../Directive/email-validator.directive';
// const timezone_country = require('../../../assets/country-timezone.js');

// interface IUser {
//   name: string;
//   companyName: string;
//   email: string;
//   phoneNumber: number;
//   password: string;
//   countryLists: any;
//   industriesList: any;
//   monthlyVolumeList: any;
// }

// interface IUserCountry {
//   countryLists: any;
//   industriesList: any;
//   monthlyVolumeList: any;
//   timeZone: string;
// }

// @Component({
//   selector: 'app-register',
//   templateUrl: './register.component.html',
//   styleUrls: ['./register.component.scss'],
//   animations: [fadeInAnimation],
//   host: { '[@fadeInAnimation]': '' },
// })
// export class RegisterComponent implements OnInit {
//   timeZoneList: any = [];
//   personalForm!: FormGroup;
//   personalCountryForm!: FormGroup;

//   separateDialCode = false;
//   preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Kuwait];
//   PhoneNumberFormat = PhoneNumberFormat;
//   SearchCountryField = SearchCountryField;
//   selectedCountryFlag: any

//   user: IUser;
//   userCountry: IUserCountry;

//   constructor(
//     public toaster: MessageService,
//     private callMethod: ApiCallService,
//     public Global: GlobalService,
//     public router: Router
//   ) {
//     this.user = {} as IUser;
//     this.userCountry = {} as IUserCountry;
//   }

//   items!: MenuItem[];
//   activeIndex: number = 0;
//   page1: boolean = true;
//   page2: boolean = false;
//   page3: boolean = false;
//   selectedCountry: any;
//   selectedTimeZone: any;
//   selectedIndustries: any;
//   selectedMonthlyVolume: any;
//   monthlyVolume: any;
//   countryList: any = [];
//   industries: any = [];
//   isValidate: boolean = false;
//   pageTitle: string = '';
//   flag: any = [];

//   ngOnInit(): void {
//     this.selectedCountryFlag = CountryISO.Kuwait
//     this.countryList = Object.values(timezone_country.getAllCountries());
//     this.pageTitle = this.Global.getLocalStorage({ 'key': 'pageTitle', });

//     this.personalForm = new FormGroup({
//       name: new FormControl('', [
//         Validators.required,
//         Validators.minLength(1),
//         Validators.maxLength(250),
//       ]),
//       companyName: new FormControl('', [
//         Validators.required,
//         Validators.maxLength(250),
//       ]),
//       phoneNumber: new FormControl('', [Validators.required]),
//       email: new FormControl('', [
//         Validators.required,
//         Validators.minLength(1),
//         Validators.maxLength(250),
//         emailValidator(),
//       ]),
//       password: new FormControl('', [
//         Validators.required,
//         Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'),
//       ]),
//     });

//     this.personalCountryForm = new FormGroup({
//       countryLists: new FormControl('', [
//         Validators.required,
//       ]),
//       industriesList: new FormControl('', [
//         Validators.required,
//       ]),
//       monthlyVolumeList: new FormControl('', [
//         Validators.required,
//       ]),
//       timeZone: new FormControl('', [
//         Validators.required,
//       ]),
//     });

//     this.items = [
//       {
//         label: '',
//       },
//       {
//         label: '',
//       },
//     ];

//     this.industries = [
//       { name: 'Beverage' },
//       { name: 'Building / Construction' },
//       { name: 'Prepared Meals' },
//       { name: 'Restaurant' },
//       { name: 'Restaurant Delivery Service' },
//       { name: 'Retail / eCommerce' },
//       { name: 'Shipping / Postal' },
//       { name: 'Third Party Logistics' },
//       { name: 'Waste Management / Recycling' },
//       { name: 'Cannabis' },
//       { name: 'Concierge' },
//       { name: 'Courier' },
//       { name: 'Desserts / Novelties' },
//       { name: 'Grocery / Produce' },
//       { name: 'Laundry / Drycleaning' },
//       { name: 'Moving / Storage' },
//       { name: 'Pharmacy / Medical' },
//       { name: 'Other' },
//     ];

//     this.monthlyVolume = [
//       { name: '0 - 100' },
//       { name: '101 - 2,500' },
//       { name: '2,501 - 5,000' },
//       { name: '5,001 - 12,500' },
//       { name: '12,501 - 25,000' },
//       { name: '25,001 - 50,000' },
//       { name: '50,000+' },
//     ];
//   }

//   get name() {
//     return this.personalForm.get('name')!;
//   }
//   get companyName() {
//     return this.personalForm.get('companyName')!;
//   }
//   get email() {
//     return this.personalForm.get('email')!;
//   }
//   get phoneNumber() {
//     return this.personalForm.get('phoneNumber')!;
//   }
//   get password() {
//     return this.personalForm.get('password')!;
//   }
//   get industriesList() {
//     return this.personalCountryForm.get('industriesList')!;
//   }
//   get countryLists() {
//     return this.personalCountryForm.get('countryLists')!;
//   }
//   get monthlyVolumeList() {
//     return this.personalCountryForm.get('monthlyVolumeList')!;
//   }
//   get timeZone() {
//     return this.personalCountryForm.get('timeZone')!;
//   }


//   validate(string: any) {
//     if (string == 'personalForm') {
//       if (this.personalForm.invalid) {
//         this.isValidate = true;
//         for (const control of Object.keys(this.personalForm.controls)) {
//           this.personalForm.controls[control].markAsTouched();
//         }
//         return;
//       } else {
//         this.signUpCall();
//       }
//     }

//     if (string == 'countryData') {
//       if (this.personalCountryForm.invalid) {
//         for (const control of Object.keys(this.personalCountryForm.controls)) {
//           this.personalCountryForm.controls[control].markAsTouched();
//         }
//         return;
//       } else {
//         this.editUserCall();
//       }
//     }
//   }

//   loginBtn: boolean = false;
//   signUpCall() {
//     this.Global.getLocalStorage({ 'key': 'JwtToken' });
//     let timeZone = timezone_country.getTimezonesForCountry(this.personalForm.value.phoneNumber.countryCode)

//     this.user = this.personalForm.value;
//     console.log('this.personalForm.value;', this.personalForm.value);
//     let userPhoneNumber = this.Global.getUserPhoneNumber(this.user.phoneNumber)
//     this.loginBtn = true;
//     let reqObj = {
//       fullName: this.user.name,
//       companyName: this.user.companyName,
//       email: this.user.email,
//       phone: userPhoneNumber,
//       password: this.user.password,
//       timezone: timeZone[0].name,
//     };

//     console.log('signUpCall() reqObj ::::::', reqObj);
//     let merge = this.user.email + userPhoneNumber + this.Global.companyDetails.companyId;
//     this.Global.setHeaderEncryptData(merge);
//     this.callMethod.signUpCall(reqObj).subscribe((result: any) => {
//       console.log('********************************** signUpCall() **********************************', result);
//       if (result.status) {
//         this.Global.setLocalStorage({
//           'key': 'JwtToken',
//           value: result.token,
//         });
//         this.Global.setLocalStorage({
//           'key': 'userId',
//           value: result.data.userId,
//         });
//         this.Global.setLocalStorage({
//           'key': 'type',
//           value: result.data.type,
//         });
//         this.activeIndex = 1;
//         this.page1 = false;
//         this.page2 = true;
//       } else {
//         this.toaster.add({
//           severity: 'error',
//           summary: 'Error',
//           detail: result.message,
//         });
//       }
//       this.loginBtn = false;
//     });
//   }

//   editUserCall() {
//     this.user = this.personalForm.value;
//     this.loginBtn = true;
//     this.userCountry = this.personalCountryForm.value;
//     console.log('this.userCountry ::::::', this.userCountry);

//     let reqObj = {
//       industryType: this.userCountry.industriesList.name,
//       industryMonthlyVolume: this.userCountry.monthlyVolumeList.name,
//       timezone: this.personalCountryForm.value.timeZone.name,
//       userId: (this.Global.getLocalStorage({ key: 'userId' }) != undefined ? this.Global.getLocalStorage({ key: 'userId' }) : this.Global.userDetails.userId),
//       currency: this.registerContryCode[0].currency,
//       country: this.registerContryCode[0].name
//     };

//     console.log('signUpCall() reqObj ::::::', reqObj);

//     let merge = reqObj.userId + this.Global.companyDetails.companyId;
//     this.Global.setHeaderEncryptData(merge);
//     this.callMethod.editUserCall(reqObj).subscribe((result: any) => {
//       console.log('********************************** register.editUserCall() **********************************', result);
//       if (result.status) {
//         this.Global.setLocalStorage({
//           'key': 'JwtToken',
//           value: result.token,
//         });
        
//         this.Global.setLocalStorage({
//           'key': 'type',
//           value: result.data.type,
//         });
//         this.router.navigateByUrl('dashboard');

//       } else {
//         this.toaster.add({
//           severity: 'error',
//           summary: 'Error',
//           detail: result.message,
//         });
//       }
//       this.loginBtn = false;
//     });
//   }
//   registerContryCode: any = ""
//   selectedCountries(selectedCountry: any) {
//     console.log('***** selectedCountry *****',selectedCountry);
//     this.registerContryCode = this.Global.countryCodeJson.filter((obj:any)=> obj.name == selectedCountry.name);
//     console.log('***** registerContryCode *****',this.registerContryCode);
//     console.log('***** registerContryCode currency *****',this.registerContryCode[0].currency);
//     console.log('***** registerContryCode name *****',this.registerContryCode[0].name);
//     this.timeZoneList = timezone_country.getTimezonesForCountry(selectedCountry.id)
//   }

//   nextPage(isActivePage: string) {
//     if (isActivePage == 'page_1') {
//       this.activeIndex = 1;
//       this.page1 = false;
//       this.page2 = true;
//     }
//     if (isActivePage == 'page_2') {
//       this.activeIndex = 0;
//       this.page1 = true;
//       this.page2 = false;
//     }
//   }
// }
