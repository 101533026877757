import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import * as dark_map from '../../assets/map_styles/dark.json';
import { ApiCallService } from './api-call.service';
import { DataShareService } from './data-share.service';
import * as moment from 'moment';
import { resolve } from 'path';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class GlobalService {

    // !index  db 
    private dbName = 'wfleet';
    private objectStoreName = 'myObjectStore';

    renderData: any = null;
    mapStyle: any;
    getRestaurantCount: number = 0;


    USER_TYPE: any = {
        superAdmin: 1,
        parentUser: 2,
        admin: 3,
        dispatcher: 4,
        driver: 5,
        system: 6
    } as const;

    trackingURL: string = '';
    driverAppURL: string = '';

    // minuteArray: any = [
    //     { minutes: "00 min.", value: 0 },
    //     { minutes: "05 min.", value: 5 },
    //     { minutes: "10 min.", value: 10 },
    //     { minutes: "15 min.", value: 15 },
    //     { minutes: "20 min.", value: 20 },
    //     { minutes: "25 min.", value: 25 },
    //     { minutes: "30 min.", value: 30 },
    //     { minutes: "35 min.", value: 35 },
    //     { minutes: "40 min.", value: 40 },
    //     { minutes: "45 min.", value: 45 },
    //     { minutes: "50 min.", value: 50 },
    //     { minutes: "55 min.", value: 55 },
    //     { minutes: "60 min.", value: 60 } // Added for completeness
    // ] as const;

    minuteArray: any = [
        0,
        5,
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
        55,
        60  // Added for completeness
    ] as const;



    allDateFormat: any = {
        '1': 'h:mm A, Do MMM YYYY',
        '2': 'DD/MM/YYYY HH:mm:ss',
        '3': 'DD/YYYY/MM hh:mm:ss a',
        '4': 'DD/YYYY/MM HH:mm:ss',
        '5': 'MM/DD/YYYY hh:mm:ss a',
        '6': 'MM/DD/YYYY HH:mm:ss',
        '7': 'MM/YYYY/DD hh:mm:ss a',
        '8': 'MM/YYYY/DD HH:mm:ss',
        '9': 'YYYY/DD/MM hh:mm:ss a',
        '10': 'YYYY/DD/MM HH:mm:ss',
        '11': 'YYYY/MM/DD hh:mm:ss a',
        '12': 'YYYY/MM/DD HH:mm:ss',
        '13': 'DD/MM/YYYY hh:mm:ss a',
    };



    constructor(public CallMethod: ApiCallService, public dataShareService: DataShareService) {
        this.initIdexDBDatabase()
        this.mapStyle = dark_map;
        // console.log("this.companyDetails.companyId :::: global",this.companyDetails.companyId);



    }




    carImgUrl = environment.baseUrl + environment.imageUrl + environment.cars;
    driverImgUrl = environment.baseUrl + environment.imageUrl + environment.driver;
    companyCurrentTime: any;
    noInternetConnection: string = '';

    companyDetails: any = {
        companyLat: 22.2722292,
        companyLng: 70.7603054,
        companyLogo: "",
        companyFavIcon: "",
        companyTitle: "",
        companyDescription: "",
        companyId: this.getLocalStorage({ key: 'companyId' }),
        companyName: "",
        currency: "",
        companyColorCodes: "",
        companyIcon: ""
    };

    parentUserId: any = "";

    userDetails: any = {
        JwtToken: "",
        userId: "",
        fullName: "",
        userCompanyName: "",
        organizationEmail: "",
        organizationExpiryDate: "",
        phone: "",
        email: "",
        type: "",
        timezone: "",
        roles: "",
        parentUser: "",
        currency: "",
        country: ""
    };

    UUID: string = '';

    settingInfo: any = {
        dayStartTime: "",
        dayEndTime: "",
        dateFormat: "",
        addressType: ""
    };

    userTimeZone: any = [];
    companyUserTimeZone: any = '';

    manageAlertPopup: any = {};

    //  set  localStorage for splitter
    isCollapseSlitter: any

    dashboardPageSplitterSize: any =
        {
            'dashboardMapPane': "65%",
            'dashboardTablePane': "35%",
        }

    audioObj: any = {
        'bookingAlert': {
            'rejectOrder': true,
            'acceptOrder': true,
        },
        'driverAlert': {
            'offlineWarning': true,
            'online': false,
            'offline': true
        },
        'unassignTab': {
            'bookingAssignRemainder': true
        },
        'preOrder': {
            'assignReminder': true
        },
        'orderShuffle':
        {
            'orderShuffleAlert': true
        }
    }


    setHeaderEncryptData(data: any) {
        // console.log("setHeaderEncryptData data =========== :::::", data);
        if (data != null && data != undefined && data != '') {
            this.generateUUID();
            let encryptData = data + this.UUID;
            // console.log('encryptData merge :::::::', encryptData);
            let getEncryptVal: any = CryptoJS.SHA256(encryptData);
            this.barrierKey = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(getEncryptVal, environment.ForceApiKey));
        }
    }

    barrierKey = '';

    generateUUID() {
        let dictionary = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPWRSTUVWXYZ0123456789-!@#$%&=+";
        let UUID1 = '';
        for (let i = 0; i < 64; i++) {
            UUID1 += [Math.floor(Math.random() * dictionary.length)];
        }
        this.UUID = UUID1;
    }

    encryptData(data: any) {
        try {
            let getEncryptVal: any = "";
            if (typeof data === "object") {
                getEncryptVal = CryptoJS.AES.encrypt(
                    JSON.stringify(data),
                    environment.encryptSecretKey
                ).toString();
            } else {
                getEncryptVal = CryptoJS.AES.encrypt(
                    data,
                    environment.encryptSecretKey
                ).toString();
            }
            return getEncryptVal;
        } catch (e) {
            return undefined;
        }
    }


    decryptData(data: any) {
        try {
            if (!data) {
                return undefined; // Handle null or undefined input
            }

            const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

            if (decryptedString) {
                try {
                    const jsonData = JSON.parse(decryptedString);
                    if (typeof jsonData === "object" && jsonData !== null) {
                        return jsonData.value;
                    }
                } catch (jsonError) {
                    // If JSON.parse fails, return the plain decrypted string
                    return decryptedString;
                }
            }
            return undefined; // Return undefined if decryption fails
        } catch (e) {
            console.error('Decryption error:', e);
            return undefined;
        }
    }

    // decryptData(data: any) {
    //     try {
    //         const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
    //         if (bytes.toString()) {
    //             const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    //             try {
    //                 const jsonData = JSON.parse(decryptedString);
    //                 if (typeof jsonData === "object" && jsonData !== null) {
    //                     return jsonData.value;
    //                 }
    //             } catch (e) {
    //                 return decryptedString;
    //             }
    //             return decryptedString;
    //         }
    //         return undefined;
    //     } catch (e) {
    //         console.log(e);
    //         return undefined;
    //     }
    // }

    setLocalStorage(opts: any) {
        let value: string = "" + this.encryptData({ value: opts.value });
        localStorage.setItem(
            Md5.hashAsciiStr(environment.baseUrl + opts.key).toString(),
            value
        );
    }

    getLocalStorage(opts: { key: any }) {
        let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
        return this.decryptData(localStorage.getItem(key));
    }

    removeLocalStorage(opts: { key: any }) {
        let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
        return localStorage.removeItem(key);
    }

    // hide show password
    fieldTextType: boolean = false;
    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    convertFormat(format: any) {
        return this.allDateFormat[format] || 'YYYY-MM-DD HH:mm:ss'
    }

    //! global input felid only input number
    numberOnly(event: any): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    sessionId: string = '';
    getSessionId() {
        if (this.sessionId == '') {
            this.sessionId = Math.floor(1000 + Math.random() * 9000).toString();
        }
        return this.sessionId;
    }

    filterByValueObj(
        array: any[],
        propertyName: string = '',
        value: string = ''
    ) {
        return array.filter(
            (data) =>
                JSON.stringify(data[propertyName])
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1
        );
    }

    vroomDriverSpeedFactor: number = environment.vroomDriverSpeedFactor;
    userSettings: any = {}
    isLoadDashboard: boolean = false;
    organizationCountryCode: any = ''
    setDateFormat = "";
    isDashboardFailed: boolean = false;
    dashboardApiCall() {
        return new Promise(resolve => {
            let requestObj = {
                // userId : this.getLocalStorage({ key: 'userId' }) != undefined ? this.getLocalStorage({ key: 'userId' }) : this.userDetails.userId,
                type: (this.userDetails.type && this.userDetails.type != '' ? this.userDetails.type : (this.getLocalStorage({ 'key': 'type' }) != undefined ? this.getLocalStorage({ 'key': 'type' }) : ''))
            }
            // console.log('****** requestObj ******', requestObj);
            console.log('****** this.parentUserId ******', this.parentUserId);
            this.CallMethod.dashboard(requestObj).subscribe((result: any) => {
                // console.log('********************************** Dashboard dashboardApiCall() **********************************', result);
                if (result.status) {
                    this.parentUserId = result.data.organization.organizationId
                    this.settingInfo.dayStartTime = result.data.dayStartTime
                    this.settingInfo.dayEndTime = result.data.dayEndTime
                    this.userTimeZone = result.data.timezone
                    this.companyUserTimeZone = result.data.timezone
                    this.userDetails.timezone = result.data.timezone
                    this.userDetails.userId = result.data.userId
                    this.userDetails.parentUser = result.data.parentUser
                    this.userDetails.phone = result.data.phone
                    this.userDetails.fullName = result.data.fullName
                    this.userDetails.userCompanyName = result.data.organization.organizationName
                    this.userDetails.organizationEmail = result.data.organization.organizationEmail
                    this.userDetails.organizationExpiryDate = result.data.organization.organizationExpiryDate
                    this.userDetails.email = result.data.email
                    this.userDetails.type = result.data.type
                    this.userDetails.roles = result.data.roles
                    this.userSettings = result.data.settings
                    this.userDetails.isAccountOwner = result.data.isAccountOwner
                    this.userDetails.currency = result.data.organization.organizationCurrency
                    this.userDetails.country = result.data.organization.organizationCountry
                    this.userDetails.latLong = result.data.latLong
                    this.setDateFormat = this.convertFormat('1')
                    this.trackingURL = result.data.trackingUrl
                    this.driverAppURL = result.data.driverAppUrl
                    this.setLocalStorage({ 'key': 'userId', value: result.data.userId, });
                    this.organizationCountryCode = this.countryCodeJson.filter((event: any) => event.name == this.userDetails.country)
                    this.isLoadDashboard = true;
                    console.log('****** this.userSettings ******', this.userSettings);
                    resolve(result.status);
                }
                else if (!result.status && result.statusCode == 426) {
                    this.isDashboardFailed = true;
                }
            });
        })
    }

    // trailLeftDays: any = null;
    // calculateDaysLeft(expiryDate: string): number {
    //     const currentDate = new Date();
    //     const expiry = new Date(expiryDate);
    //     console.log('****** expiryDate ******', expiryDate);
    //     console.log('****** currentDate ******', currentDate);
    //     const timeDifference = expiry.getTime() - currentDate.getTime();
    //     console.log('****** timeDifference ******', timeDifference);
    //     return Math.ceil(timeDifference / (1000 * 3600 * 24));
    // }


    setCompanyTimeZoneFormat = (dateTime: any) => moment(dateTime).format('h:mm A, Do MMM YYYY');

    countryCodeJson: any[] = [
        {
            "name": "Afghanistan",
            "dial_code": "+93",
            "code": "AF",
            "currency": {
                "code": "AFN",
                "name": "Afghani",
                "symbol": "؋"
            }
        },
        {
            "name": "Albania",
            "dial_code": "+355",
            "code": "AL",
            "currency": {
                "code": "ALL",
                "name": "Lek",
                "symbol": "Lek"
            }
        },
        {
            "name": "Algeria",
            "dial_code": "+213",
            "code": "DZ",
            "currency": {
                "code": "DZD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "AmericanSamoa",
            "dial_code": "+1684",
            "code": "AS",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Andorra",
            "dial_code": "+376",
            "code": "AD",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Angola",
            "dial_code": "+244",
            "code": "AO",
            "currency": {
                "code": "AOA",
                "name": "Kwanza",
                "symbol": "Kz"
            }
        },
        {
            "name": "Anguilla",
            "dial_code": "+1264",
            "code": "AI",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Antarctica",
            "dial_code": "+672",
            "code": "AQ",
            "currency": {
                "code": "",
                "name": "",
                "symbol": false
            }
        },
        {
            "name": "Antigua and Barbuda",
            "dial_code": "+1268",
            "code": "AG",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Argentina",
            "dial_code": "+54",
            "code": "AR",
            "currency": {
                "code": "ARS",
                "name": "Peso",
                "symbol": "$"
            }
        },
        {
            "name": "Armenia",
            "dial_code": "+374",
            "code": "AM",
            "currency": {
                "code": "AMD",
                "name": "Dram",
                "symbol": false
            }
        },
        {
            "name": "Aruba",
            "dial_code": "+297",
            "code": "AW",
            "currency": {
                "code": "AWG",
                "name": "Guilder",
                "symbol": "ƒ"
            }
        },
        {
            "name": "Australia",
            "dial_code": "+61",
            "code": "AU",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Austria",
            "dial_code": "+43",
            "code": "AT",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Azerbaijan",
            "dial_code": "+994",
            "code": "AZ",
            "currency": {
                "code": "AZN",
                "name": "Manat",
                "symbol": "ман"
            }
        },
        {
            "name": "Bahamas",
            "dial_code": "+1242",
            "code": "BS",
            "currency": {
                "code": "BSD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Bahrain",
            "dial_code": "+973",
            "code": "BH",
            "currency": {
                "code": "BHD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Bangladesh",
            "dial_code": "+880",
            "code": "BD",
            "currency": {
                "code": "BDT",
                "name": "Taka",
                "symbol": false
            }
        },
        {
            "name": "Barbados",
            "dial_code": "+1246",
            "code": "BB",
            "currency": {
                "code": "BBD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Belarus",
            "dial_code": "+375",
            "code": "BY",
            "currency": {
                "code": "BYR",
                "name": "Ruble",
                "symbol": "p."
            }
        },
        {
            "name": "Belgium",
            "dial_code": "+32",
            "code": "BE",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Belize",
            "dial_code": "+501",
            "code": "BZ",
            "currency": {
                "code": "BZD",
                "name": "Dollar",
                "symbol": "BZ$"
            }
        },
        {
            "name": "Benin",
            "dial_code": "+229",
            "code": "BJ",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Bermuda",
            "dial_code": "+1441",
            "code": "BM",
            "currency": {
                "code": "BMD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Bhutan",
            "dial_code": "+975",
            "code": "BT",
            "currency": {
                "code": "BTN",
                "name": "Ngultrum",
                "symbol": false
            }
        },
        {
            "name": "Bolivia, Plurinational State of",
            "dial_code": "+591",
            "code": "BO",
            "currency": {
                "code": "BOB",
                "name": "Boliviano",
                "symbol": "$b"
            }
        },
        {
            "name": "Bosnia and Herzegovina",
            "dial_code": "+387",
            "code": "BA",
            "currency": {
                "code": "BAM",
                "name": "Marka",
                "symbol": "KM"
            }
        },
        {
            "name": "Botswana",
            "dial_code": "+267",
            "code": "BW",
            "currency": {
                "code": "BWP",
                "name": "Pula",
                "symbol": "P"
            }
        },
        {
            "name": "Brazil",
            "dial_code": "+55",
            "code": "BR",
            "currency": {
                "code": "BRL",
                "name": "Real",
                "symbol": "R$"
            }
        },
        {
            "name": "British Indian Ocean Territory",
            "dial_code": "+246",
            "code": "IO",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Brunei Darussalam",
            "dial_code": "+673",
            "code": "BN",
            "currency": {
                "code": "BND",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Bulgaria",
            "dial_code": "+359",
            "code": "BG",
            "currency": {
                "code": "BGN",
                "name": "Lev",
                "symbol": "лв"
            }
        },
        {
            "name": "Burkina Faso",
            "dial_code": "+226",
            "code": "BF",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Burundi",
            "dial_code": "+257",
            "code": "BI",
            "currency": {
                "code": "BIF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Cambodia",
            "dial_code": "+855",
            "code": "KH",
            "currency": {
                "code": "KHR",
                "name": "Riels",
                "symbol": "៛"
            }
        },
        {
            "name": "Cameroon",
            "dial_code": "+237",
            "code": "CM",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": "FCF"
            }
        },
        {
            "name": "Canada",
            "dial_code": "+1",
            "code": "CA",
            "currency": {
                "code": "CAD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Cape Verde",
            "dial_code": "+238",
            "code": "CV",
            "currency": {
                "code": "CVE",
                "name": "Escudo",
                "symbol": false
            }
        },
        {
            "name": "Cayman Islands",
            "dial_code": "+ 345",
            "code": "KY",
            "currency": {
                "code": "KYD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Central African Republic",
            "dial_code": "+236",
            "code": "CF",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": "FCF"
            }
        },
        {
            "name": "Chad",
            "dial_code": "+235",
            "code": "TD",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Chile",
            "dial_code": "+56",
            "code": "CL",
            "currency": {
                "code": "CLP",
                "name": "Peso",
                "symbol": false
            }
        },
        {
            "name": "China",
            "dial_code": "+86",
            "code": "CN",
            "currency": {
                "code": "CNY",
                "name": "YuanRenminbi",
                "symbol": "¥"
            }
        },
        {
            "name": "Christmas Island",
            "dial_code": "+61",
            "code": "CX",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Cocos (Keeling) Islands",
            "dial_code": "+61",
            "code": "CC",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Colombia",
            "dial_code": "+57",
            "code": "CO",
            "currency": {
                "code": "COP",
                "name": "Peso",
                "symbol": "$"
            }
        },
        {
            "name": "Comoros",
            "dial_code": "+269",
            "code": "KM",
            "currency": {
                "code": "KMF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Congo",
            "dial_code": "+242",
            "code": "CG",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": "FCF"
            }
        },
        {
            "name": "Congo, The Democratic Republic of the Congo",
            "dial_code": "+243",
            "code": "CD",
            "currency": {
                "code": "CDF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Cook Islands",
            "dial_code": "+682",
            "code": "CK",
            "currency": {
                "code": "NZD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Costa Rica",
            "dial_code": "+506",
            "code": "CR",
            "currency": {
                "code": "CRC",
                "name": "Colon",
                "symbol": "₡"
            }
        },
        {
            "name": "Cote d'Ivoire",
            "dial_code": "+225",
            "code": "CI",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Croatia",
            "dial_code": "+385",
            "code": "HR",
            "currency": {
                "code": "HRK",
                "name": "Kuna",
                "symbol": "kn"
            }
        },
        {
            "name": "Cuba",
            "dial_code": "+53",
            "code": "CU",
            "currency": {
                "code": "CUP",
                "name": "Peso",
                "symbol": "₱"
            }
        },
        {
            "name": "Cyprus",
            "dial_code": "+357",
            "code": "CY",
            "currency": {
                "code": "CYP",
                "name": "Pound",
                "symbol": false
            }
        },
        {
            "name": "Czech Republic",
            "dial_code": "+420",
            "code": "CZ",
            "currency": {
                "code": "CZK",
                "name": "Koruna",
                "symbol": "Kč"
            }
        },
        {
            "name": "Denmark",
            "dial_code": "+45",
            "code": "DK",
            "currency": {
                "code": "DKK",
                "name": "Krone",
                "symbol": "kr"
            }
        },
        {
            "name": "Djibouti",
            "dial_code": "+253",
            "code": "DJ",
            "currency": {
                "code": "DJF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Dominica",
            "dial_code": "+1767",
            "code": "DM",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Dominican Republic",
            "dial_code": "+1849",
            "code": "DO",
            "currency": {
                "code": "DOP",
                "name": "Peso",
                "symbol": "RD$"
            }
        },
        {
            "name": "Ecuador",
            "dial_code": "+593",
            "code": "EC",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Egypt",
            "dial_code": "+20",
            "code": "EG",
            "currency": {
                "code": "EGP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "El Salvador",
            "dial_code": "+503",
            "code": "SV",
            "currency": {
                "code": "SVC",
                "name": "Colone",
                "symbol": "$"
            }
        },
        {
            "name": "Equatorial Guinea",
            "dial_code": "+240",
            "code": "GQ",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": "FCF"
            }
        },
        {
            "name": "Eritrea",
            "dial_code": "+291",
            "code": "ER",
            "currency": {
                "code": "ERN",
                "name": "Nakfa",
                "symbol": "Nfk"
            }
        },
        {
            "name": "Estonia",
            "dial_code": "+372",
            "code": "EE",
            "currency": {
                "code": "EEK",
                "name": "Kroon",
                "symbol": "kr"
            }
        },
        {
            "name": "Ethiopia",
            "dial_code": "+251",
            "code": "ET",
            "currency": {
                "code": "ETB",
                "name": "Birr",
                "symbol": false
            }
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "dial_code": "+500",
            "code": "FK",
            "currency": {
                "code": "FKP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Faroe Islands",
            "dial_code": "+298",
            "code": "FO",
            "currency": {
                "code": "DKK",
                "name": "Krone",
                "symbol": "kr"
            }
        },
        {
            "name": "Fiji",
            "dial_code": "+679",
            "code": "FJ",
            "currency": {
                "code": "FJD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Finland",
            "dial_code": "+358",
            "code": "FI",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "France",
            "dial_code": "+33",
            "code": "FR",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "French Guiana",
            "dial_code": "+594",
            "code": "GF",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "French Polynesia",
            "dial_code": "+689",
            "code": "PF",
            "currency": {
                "code": "XPF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Gabon",
            "dial_code": "+241",
            "code": "GA",
            "currency": {
                "code": "XAF",
                "name": "Franc",
                "symbol": "FCF"
            }
        },
        {
            "name": "Gambia",
            "dial_code": "+220",
            "code": "GM",
            "currency": {
                "code": "GMD",
                "name": "Dalasi",
                "symbol": "D"
            }
        },
        {
            "name": "Georgia",
            "dial_code": "+995",
            "code": "GE",
            "currency": {
                "code": "GEL",
                "name": "Lari",
                "symbol": false
            }
        },
        {
            "name": "Germany",
            "dial_code": "+49",
            "code": "DE",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Ghana",
            "dial_code": "+233",
            "code": "GH",
            "currency": {
                "code": "GHC",
                "name": "Cedi",
                "symbol": "¢"
            }
        },
        {
            "name": "Gibraltar",
            "dial_code": "+350",
            "code": "GI",
            "currency": {
                "code": "GIP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Greece",
            "dial_code": "+30",
            "code": "GR",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Greenland",
            "dial_code": "+299",
            "code": "GL",
            "currency": {
                "code": "DKK",
                "name": "Krone",
                "symbol": "kr"
            }
        },
        {
            "name": "Grenada",
            "dial_code": "+1473",
            "code": "GD",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Guadeloupe",
            "dial_code": "+590",
            "code": "GP",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Guam",
            "dial_code": "+1671",
            "code": "GU",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Guatemala",
            "dial_code": "+502",
            "code": "GT",
            "currency": {
                "code": "GTQ",
                "name": "Quetzal",
                "symbol": "Q"
            }
        },
        {
            "name": "Guinea",
            "dial_code": "+224",
            "code": "GN",
            "currency": {
                "code": "GNF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Guinea-Bissau",
            "dial_code": "+245",
            "code": "GW",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Guyana",
            "dial_code": "+595",
            "code": "GY",
            "currency": {
                "code": "GYD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Haiti",
            "dial_code": "+509",
            "code": "HT",
            "currency": {
                "code": "HTG",
                "name": "Gourde",
                "symbol": "G"
            }
        },
        {
            "name": "Holy See (Vatican City State)",
            "dial_code": "+379",
            "code": "VA",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Honduras",
            "dial_code": "+504",
            "code": "HN",
            "currency": {
                "code": "HNL",
                "name": "Lempira",
                "symbol": "L"
            }
        },
        {
            "name": "Hong Kong",
            "dial_code": "+852",
            "code": "HK",
            "currency": {
                "code": "HKD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Hungary",
            "dial_code": "+36",
            "code": "HU",
            "currency": {
                "code": "HUF",
                "name": "Forint",
                "symbol": "Ft"
            }
        },
        {
            "name": "Iceland",
            "dial_code": "+354",
            "code": "IS",
            "currency": {
                "code": "ISK",
                "name": "Krona",
                "symbol": "kr"
            }
        },
        {
            "name": "India",
            "dial_code": "+91",
            "code": "IN",
            "currency": {
                "code": "INR",
                "name": "Rupee",
                "symbol": "₹"
            }
        },
        {
            "name": "Indonesia",
            "dial_code": "+62",
            "code": "ID",
            "currency": {
                "code": "IDR",
                "name": "Rupiah",
                "symbol": "Rp"
            }
        },
        {
            "name": "Iran, Islamic Republic of Persian Gulf",
            "dial_code": "+98",
            "code": "IR",
            "currency": {
                "code": "IRR",
                "name": "Rial",
                "symbol": "﷼"
            }
        },
        {
            "name": "Iraq",
            "dial_code": "+964",
            "code": "IQ",
            "currency": {
                "code": "IQD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Ireland",
            "dial_code": "+353",
            "code": "IE",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Italy",
            "dial_code": "+39",
            "code": "IT",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Jamaica",
            "dial_code": "+1876",
            "code": "JM",
            "currency": {
                "code": "JMD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Japan",
            "dial_code": "+81",
            "code": "JP",
            "currency": {
                "code": "JPY",
                "name": "Yen",
                "symbol": "¥"
            }
        },
        {
            "name": "Jordan",
            "dial_code": "+962",
            "code": "JO",
            "currency": {
                "code": "JOD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Kazakhstan",
            "dial_code": "+77",
            "code": "KZ",
            "currency": {
                "code": "KZT",
                "name": "Tenge",
                "symbol": "лв"
            }
        },
        {
            "name": "Kenya",
            "dial_code": "+254",
            "code": "KE",
            "currency": {
                "code": "KES",
                "name": "Shilling",
                "symbol": false
            }
        },
        {
            "name": "Kiribati",
            "dial_code": "+686",
            "code": "KI",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Korea, Democratic People's Republic of Korea",
            "dial_code": "+850",
            "code": "KP",
            "currency": {
                "code": "KPW",
                "name": "Won",
                "symbol": "₩"
            }
        },
        {
            "name": "Korea, Republic of South Korea",
            "dial_code": "+82",
            "code": "KR",
            "currency": {
                "code": "KRW",
                "name": "Won",
                "symbol": "₩"
            }
        },
        {
            "name": "Kuwait",
            "dial_code": "+965",
            "code": "KW",
            "currency": {
                "code": "KWD",
                "name": "Dinar",
                "symbol": 'KWD'
            }
        },
        {
            "name": "Kyrgyzstan",
            "dial_code": "+996",
            "code": "KG",
            "currency": {
                "code": "KGS",
                "name": "Som",
                "symbol": "лв"
            }
        },
        {
            "name": "Laos",
            "dial_code": "+856",
            "code": "LA",
            "currency": {
                "code": "LAK",
                "name": "Kip",
                "symbol": "₭"
            }
        },
        {
            "name": "Latvia",
            "dial_code": "+371",
            "code": "LV",
            "currency": {
                "code": "LVL",
                "name": "Lat",
                "symbol": "Ls"
            }
        },
        {
            "name": "Lebanon",
            "dial_code": "+961",
            "code": "LB",
            "currency": {
                "code": "LBP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Lesotho",
            "dial_code": "+266",
            "code": "LS",
            "currency": {
                "code": "LSL",
                "name": "Loti",
                "symbol": "L"
            }
        },
        {
            "name": "Liberia",
            "dial_code": "+231",
            "code": "LR",
            "currency": {
                "code": "LRD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Libyan Arab Jamahiriya",
            "dial_code": "+218",
            "code": "LY",
            "currency": {
                "code": "LYD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Liechtenstein",
            "dial_code": "+423",
            "code": "LI",
            "currency": {
                "code": "CHF",
                "name": "Franc",
                "symbol": "CHF"
            }
        },
        {
            "name": "Lithuania",
            "dial_code": "+370",
            "code": "LT",
            "currency": {
                "code": "LTL",
                "name": "Litas",
                "symbol": "Lt"
            }
        },
        {
            "name": "Luxembourg",
            "dial_code": "+352",
            "code": "LU",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Macao",
            "dial_code": "+853",
            "code": "MO",
            "currency": {
                "code": "MOP",
                "name": "Pataca",
                "symbol": "MOP"
            }
        },
        {
            "name": "Macedonia",
            "dial_code": "+389",
            "code": "MK",
            "currency": {
                "code": "MKD",
                "name": "Denar",
                "symbol": "ден"
            }
        },
        {
            "name": "Madagascar",
            "dial_code": "+261",
            "code": "MG",
            "currency": {
                "code": "MGA",
                "name": "Ariary",
                "symbol": false
            }
        },
        {
            "name": "Malawi",
            "dial_code": "+265",
            "code": "MW",
            "currency": {
                "code": "MWK",
                "name": "Kwacha",
                "symbol": "MK"
            }
        },
        {
            "name": "Malaysia",
            "dial_code": "+60",
            "code": "MY",
            "currency": {
                "code": "MYR",
                "name": "Ringgit",
                "symbol": "RM"
            }
        },
        {
            "name": "Maldives",
            "dial_code": "+960",
            "code": "MV",
            "currency": {
                "code": "MVR",
                "name": "Rufiyaa",
                "symbol": "Rf"
            }
        },
        {
            "name": "Mali",
            "dial_code": "+223",
            "code": "ML",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Malta",
            "dial_code": "+356",
            "code": "MT",
            "currency": {
                "code": "MTL",
                "name": "Lira",
                "symbol": false
            }
        },
        {
            "name": "Marshall Islands",
            "dial_code": "+692",
            "code": "MH",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Martinique",
            "dial_code": "+596",
            "code": "MQ",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Mauritania",
            "dial_code": "+222",
            "code": "MR",
            "currency": {
                "code": "MRO",
                "name": "Ouguiya",
                "symbol": "UM"
            }
        },
        {
            "name": "Mauritius",
            "dial_code": "+230",
            "code": "MU",
            "currency": {
                "code": "MUR",
                "name": "Rupee",
                "symbol": "₨"
            }
        },
        {
            "name": "Mayotte",
            "dial_code": "+262",
            "code": "YT",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Mexico",
            "dial_code": "+52",
            "code": "MX",
            "currency": {
                "code": "MXN",
                "name": "Peso",
                "symbol": "$"
            }
        },
        {
            "name": "Micronesia, Federated States of Micronesia",
            "dial_code": "+691",
            "code": "FM",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Moldova",
            "dial_code": "+373",
            "code": "MD",
            "currency": {
                "code": "MDL",
                "name": "Leu",
                "symbol": false
            }
        },
        {
            "name": "Monaco",
            "dial_code": "+377",
            "code": "MC",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Mongolia",
            "dial_code": "+976",
            "code": "MN",
            "currency": {
                "code": "MNT",
                "name": "Tugrik",
                "symbol": "₮"
            }
        },
        {
            "name": "Montserrat",
            "dial_code": "+1664",
            "code": "MS",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Morocco",
            "dial_code": "+212",
            "code": "MA",
            "currency": {
                "code": "MAD",
                "name": "Dirham",
                "symbol": false
            }
        },
        {
            "name": "Mozambique",
            "dial_code": "+258",
            "code": "MZ",
            "currency": {
                "code": "MZN",
                "name": "Meticail",
                "symbol": "MT"
            }
        },
        {
            "name": "Myanmar",
            "dial_code": "+95",
            "code": "MM",
            "currency": {
                "code": "MMK",
                "name": "Kyat",
                "symbol": "K"
            }
        },
        {
            "name": "Namibia",
            "dial_code": "+264",
            "code": "NA",
            "currency": {
                "code": "NAD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Nauru",
            "dial_code": "+674",
            "code": "NR",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Nepal",
            "dial_code": "+977",
            "code": "NP",
            "currency": {
                "code": "NPR",
                "name": "Rupee",
                "symbol": "₨"
            }
        },
        {
            "name": "Netherlands",
            "dial_code": "+31",
            "code": "NL",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Netherlands Antilles",
            "dial_code": "+599",
            "code": "AN",
            "currency": {
                "code": "ANG",
                "name": "Guilder",
                "symbol": "ƒ"
            }
        },
        {
            "name": "New Caledonia",
            "dial_code": "+687",
            "code": "NC",
            "currency": {
                "code": "XPF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "New Zealand",
            "dial_code": "+64",
            "code": "NZ",
            "currency": {
                "code": "NZD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Nicaragua",
            "dial_code": "+505",
            "code": "NI",
            "currency": {
                "code": "NIO",
                "name": "Cordoba",
                "symbol": "C$"
            }
        },
        {
            "name": "Niger",
            "dial_code": "+227",
            "code": "NE",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Nigeria",
            "dial_code": "+234",
            "code": "NG",
            "currency": {
                "code": "NGN",
                "name": "Naira",
                "symbol": "₦"
            }
        },
        {
            "name": "Niue",
            "dial_code": "+683",
            "code": "NU",
            "currency": {
                "code": "NZD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Norfolk Island",
            "dial_code": "+672",
            "code": "NF",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Northern Mariana Islands",
            "dial_code": "+1670",
            "code": "MP",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Norway",
            "dial_code": "+47",
            "code": "NO",
            "currency": {
                "code": "NOK",
                "name": "Krone",
                "symbol": "kr"
            }
        },
        {
            "name": "Oman",
            "dial_code": "+968",
            "code": "OM",
            "currency": {
                "code": "OMR",
                "name": "Rial",
                "symbol": "﷼"
            }
        },
        {
            "name": "Pakistan",
            "dial_code": "+92",
            "code": "PK",
            "currency": {
                "code": "PKR",
                "name": "Rupee",
                "symbol": "₨"
            }
        },
        {
            "name": "Palau",
            "dial_code": "+680",
            "code": "PW",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Palestinian Territory, Occupied",
            "dial_code": "+970",
            "code": "PS",
            "currency": {
                "code": "ILS",
                "name": "Shekel",
                "symbol": "₪"
            }
        },
        {
            "name": "Panama",
            "dial_code": "+507",
            "code": "PA",
            "currency": {
                "code": "PAB",
                "name": "Balboa",
                "symbol": "B/."
            }
        },
        {
            "name": "Papua New Guinea",
            "dial_code": "+675",
            "code": "PG",
            "currency": {
                "code": "PGK",
                "name": "Kina",
                "symbol": false
            }
        },
        {
            "name": "Paraguay",
            "dial_code": "+595",
            "code": "PY",
            "currency": {
                "code": "PYG",
                "name": "Guarani",
                "symbol": "Gs"
            }
        },
        {
            "name": "Peru",
            "dial_code": "+51",
            "code": "PE",
            "currency": {
                "code": "PEN",
                "name": "Sol",
                "symbol": "S/."
            }
        },
        {
            "name": "Philippines",
            "dial_code": "+63",
            "code": "PH",
            "currency": {
                "code": "PHP",
                "name": "Peso",
                "symbol": "Php"
            }
        },
        {
            "name": "Pitcairn",
            "dial_code": "+872",
            "code": "PN",
            "currency": {
                "code": "NZD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Poland",
            "dial_code": "+48",
            "code": "PL",
            "currency": {
                "code": "PLN",
                "name": "Zloty",
                "symbol": "zł"
            }
        },
        {
            "name": "Portugal",
            "dial_code": "+351",
            "code": "PT",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Puerto Rico",
            "dial_code": "+1939",
            "code": "PR",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Qatar",
            "dial_code": "+974",
            "code": "QA",
            "currency": {
                "code": "QAR",
                "name": "Rial",
                "symbol": "﷼"
            }
        },
        {
            "name": "Romania",
            "dial_code": "+40",
            "code": "RO",
            "currency": {
                "code": "RON",
                "name": "Leu",
                "symbol": "lei"
            }
        },
        {
            "name": "Russia",
            "dial_code": "+7",
            "code": "RU",
            "currency": {
                "code": "RUB",
                "name": "Ruble",
                "symbol": "руб"
            }
        },
        {
            "name": "Rwanda",
            "dial_code": "+250",
            "code": "RW",
            "currency": {
                "code": "RWF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Reunion",
            "dial_code": "+262",
            "code": "RE",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Saint Helena, Ascension and Tristan Da Cunha",
            "dial_code": "+290",
            "code": "SH",
            "currency": {
                "code": "SHP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Saint Kitts and Nevis",
            "dial_code": "+1869",
            "code": "KN",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Saint Lucia",
            "dial_code": "+1758",
            "code": "LC",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Saint Pierre and Miquelon",
            "dial_code": "+508",
            "code": "PM",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "dial_code": "+1784",
            "code": "VC",
            "currency": {
                "code": "XCD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Samoa",
            "dial_code": "+685",
            "code": "WS",
            "currency": {
                "code": "WST",
                "name": "Tala",
                "symbol": "WS$"
            }
        },
        {
            "name": "San Marino",
            "dial_code": "+378",
            "code": "SM",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Sao Tome and Principe",
            "dial_code": "+239",
            "code": "ST",
            "currency": {
                "code": "STD",
                "name": "Dobra",
                "symbol": "Db"
            }
        },
        {
            "name": "Saudi Arabia",
            "dial_code": "+966",
            "code": "SA",
            "currency": {
                "code": "SAR",
                "name": "Rial",
                "symbol": "﷼"
            }
        },
        {
            "name": "Senegal",
            "dial_code": "+221",
            "code": "SN",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Seychelles",
            "dial_code": "+248",
            "code": "SC",
            "currency": {
                "code": "SCR",
                "name": "Rupee",
                "symbol": "₨"
            }
        },
        {
            "name": "Sierra Leone",
            "dial_code": "+232",
            "code": "SL",
            "currency": {
                "code": "SLL",
                "name": "Leone",
                "symbol": "Le"
            }
        },
        {
            "name": "Singapore",
            "dial_code": "+65",
            "code": "SG",
            "currency": {
                "code": "SGD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Slovakia",
            "dial_code": "+421",
            "code": "SK",
            "currency": {
                "code": "SKK",
                "name": "Koruna",
                "symbol": "Sk"
            }
        },
        {
            "name": "Slovenia",
            "dial_code": "+386",
            "code": "SI",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Solomon Islands",
            "dial_code": "+677",
            "code": "SB",
            "currency": {
                "code": "SBD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Somalia",
            "dial_code": "+252",
            "code": "SO",
            "currency": {
                "code": "SOS",
                "name": "Shilling",
                "symbol": "S"
            }
        },
        {
            "name": "South Africa",
            "dial_code": "+27",
            "code": "ZA",
            "currency": {
                "code": "ZAR",
                "name": "Rand",
                "symbol": "R"
            }
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "dial_code": "+500",
            "code": "GS",
            "currency": {
                "code": "GBP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Spain",
            "dial_code": "+34",
            "code": "ES",
            "currency": {
                "code": "EUR",
                "name": "Euro",
                "symbol": "€"
            }
        },
        {
            "name": "Sri Lanka",
            "dial_code": "+94",
            "code": "LK",
            "currency": {
                "code": "LKR",
                "name": "Rupee",
                "symbol": "₨"
            }
        },
        {
            "name": "Sudan",
            "dial_code": "+249",
            "code": "SD",
            "currency": {
                "code": "SDD",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Suriname",
            "dial_code": "+597",
            "code": "SR",
            "currency": {
                "code": "SRD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Svalbard and Jan Mayen",
            "dial_code": "+47",
            "code": "SJ",
            "currency": {
                "code": "NOK",
                "name": "Krone",
                "symbol": "kr"
            }
        },
        {
            "name": "Swaziland",
            "dial_code": "+268",
            "code": "SZ",
            "currency": {
                "code": "SZL",
                "name": "Lilangeni",
                "symbol": false
            }
        },
        {
            "name": "Sweden",
            "dial_code": "+46",
            "code": "SE",
            "currency": {
                "code": "SEK",
                "name": "Krona",
                "symbol": "kr"
            }
        },
        {
            "name": "Switzerland",
            "dial_code": "+41",
            "code": "CH",
            "currency": {
                "code": "CHF",
                "name": "Franc",
                "symbol": "CHF"
            }
        },
        {
            "name": "Syrian Arab Republic",
            "dial_code": "+963",
            "code": "SY",
            "currency": {
                "code": "SYP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "Taiwan",
            "dial_code": "+886",
            "code": "TW",
            "currency": {
                "code": "TWD",
                "name": "Dollar",
                "symbol": "NT$"
            }
        },
        {
            "name": "Tajikistan",
            "dial_code": "+992",
            "code": "TJ",
            "currency": {
                "code": "TJS",
                "name": "Somoni",
                "symbol": false
            }
        },
        {
            "name": "Tanzania, United Republic of Tanzania",
            "dial_code": "+255",
            "code": "TZ",
            "currency": {
                "code": "TZS",
                "name": "Shilling",
                "symbol": false
            }
        },
        {
            "name": "Thailand",
            "dial_code": "+66",
            "code": "TH",
            "currency": {
                "code": "THB",
                "name": "Baht",
                "symbol": "฿"
            }
        },
        {
            "name": "Timor-Leste",
            "dial_code": "+670",
            "code": "TL",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Togo",
            "dial_code": "+228",
            "code": "TG",
            "currency": {
                "code": "XOF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Tokelau",
            "dial_code": "+690",
            "code": "TK",
            "currency": {
                "code": "NZD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Tonga",
            "dial_code": "+676",
            "code": "TO",
            "currency": {
                "code": "TOP",
                "name": "Paanga",
                "symbol": "T$"
            }
        },
        {
            "name": "Trinidad and Tobago",
            "dial_code": "+1868",
            "code": "TT",
            "currency": {
                "code": "TTD",
                "name": "Dollar",
                "symbol": "TT$"
            }
        },
        {
            "name": "Tunisia",
            "dial_code": "+216",
            "code": "TN",
            "currency": {
                "code": "TND",
                "name": "Dinar",
                "symbol": false
            }
        },
        {
            "name": "Turkey",
            "dial_code": "+90",
            "code": "TR",
            "currency": {
                "code": "TRY",
                "name": "Lira",
                "symbol": "YTL"
            }
        },
        {
            "name": "Turkmenistan",
            "dial_code": "+993",
            "code": "TM",
            "currency": {
                "code": "TMM",
                "name": "Manat",
                "symbol": "m"
            }
        },
        {
            "name": "Turks and Caicos Islands",
            "dial_code": "+1649",
            "code": "TC",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Tuvalu",
            "dial_code": "+688",
            "code": "TV",
            "currency": {
                "code": "AUD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Uganda",
            "dial_code": "+256",
            "code": "UG",
            "currency": {
                "code": "UGX",
                "name": "Shilling",
                "symbol": false
            }
        },
        {
            "name": "Ukraine",
            "dial_code": "+380",
            "code": "UA",
            "currency": {
                "code": "UAH",
                "name": "Hryvnia",
                "symbol": "₴"
            }
        },
        {
            "name": "United Arab Emirates",
            "dial_code": "+971",
            "code": "AE",
            "currency": {
                "code": "AED",
                "name": "Dirham",
                "symbol": false
            }
        },
        {
            "name": "United Kingdom",
            "dial_code": "+44",
            "code": "GB",
            "currency": {
                "code": "GBP",
                "name": "Pound",
                "symbol": "£"
            }
        },
        {
            "name": "United States",
            "dial_code": "+1",
            "code": "US",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Uruguay",
            "dial_code": "+598",
            "code": "UY",
            "currency": {
                "code": "UYU",
                "name": "Peso",
                "symbol": "$U"
            }
        },
        {
            "name": "Uzbekistan",
            "dial_code": "+998",
            "code": "UZ",
            "currency": {
                "code": "UZS",
                "name": "Som",
                "symbol": "лв"
            }
        },
        {
            "name": "Vanuatu",
            "dial_code": "+678",
            "code": "VU",
            "currency": {
                "code": "VUV",
                "name": "Vatu",
                "symbol": "Vt"
            }
        },
        {
            "name": "Venezuela, Bolivarian Republic of Venezuela",
            "dial_code": "+58",
            "code": "VE",
            "currency": {
                "code": "VEF",
                "name": "Bolivar",
                "symbol": "Bs"
            }
        },
        {
            "name": "Vietnam",
            "dial_code": "+84",
            "code": "VN",
            "currency": {
                "code": "VND",
                "name": "Dong",
                "symbol": "₫"
            }
        },
        {
            "name": "Virgin Islands, British",
            "dial_code": "+1284",
            "code": "VG",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Virgin Islands, U.S.",
            "dial_code": "+1340",
            "code": "VI",
            "currency": {
                "code": "USD",
                "name": "Dollar",
                "symbol": "$"
            }
        },
        {
            "name": "Wallis and Futuna",
            "dial_code": "+681",
            "code": "WF",
            "currency": {
                "code": "XPF",
                "name": "Franc",
                "symbol": false
            }
        },
        {
            "name": "Yemen",
            "dial_code": "+967",
            "code": "YE",
            "currency": {
                "code": "YER",
                "name": "Rial",
                "symbol": "﷼"
            }
        },
        {
            "name": "Zambia",
            "dial_code": "+260",
            "code": "ZM",
            "currency": {
                "code": "ZMK",
                "name": "Kwacha",
                "symbol": "ZK"
            }
        },
        {
            "name": "Zimbabwe",
            "dial_code": "+263",
            "code": "ZW",
            "currency": {
                "code": "ZWD",
                "name": "Dollar",
                "symbol": "Z$"
            }
        }
    ]


    // ! ----- new status code ------------ 

    TASK_STATUS: any = {
        UNASSIGNED: 1,
        ASSIGNED: 2,
        ACCEPTED: 3,
        DRIVER_START_TO_PICKUP: 4,
        DRIVER_ARRIVED_PICKUP: 5,
        COMPLETED_PICKUP: 6,
        DRIVER_START_TO_DROPOFF: 7,
        DRIVER_ARRIVED: 8,
        COMPLETED: 9,
        USER_CANCELLED: 10,
        DRIVER_CANCELLED: 11,
        PLANNING: 12,
        PLANNED: 13,
        ON_ROUTE: 777,
        ALL_TASK: 0,
        ALL_CANCELLED_TASKS: -1,
        MISS_DELIVERY_TASKS: 14
    } as const;

    ALL_TASKS: any = [
        this.TASK_STATUS.UNASSIGNED,
        this.TASK_STATUS.ASSIGNED,
        this.TASK_STATUS.ACCEPTED,
        this.TASK_STATUS.DRIVER_START_TO_PICKUP,
        this.TASK_STATUS.DRIVER_ARRIVED_PICKUP,
        this.TASK_STATUS.COMPLETED_PICKUP,
        this.TASK_STATUS.DRIVER_START_TO_DROPOFF,
        this.TASK_STATUS.DRIVER_ARRIVED,
        this.TASK_STATUS.PLANNING,
        this.TASK_STATUS.PLANNED,
        this.TASK_STATUS.MISS_DELIVERY_TASKS,
    ] as const


    ALL_TASKS_MARKERS: number[] = [
        this.TASK_STATUS.ASSIGNED,
        this.TASK_STATUS.ACCEPTED,
        this.TASK_STATUS.DRIVER_START_TO_PICKUP,
        this.TASK_STATUS.DRIVER_ARRIVED_PICKUP,
        this.TASK_STATUS.COMPLETED_PICKUP,
        this.TASK_STATUS.DRIVER_START_TO_DROPOFF,
        this.TASK_STATUS.DRIVER_ARRIVED,
    ]

    ALL_ON_ROUTE: any = [
        this.TASK_STATUS.DRIVER_START_TO_PICKUP,
        this.TASK_STATUS.DRIVER_ARRIVED_PICKUP,
        this.TASK_STATUS.COMPLETED_PICKUP,
        this.TASK_STATUS.DRIVER_START_TO_DROPOFF,
        this.TASK_STATUS.DRIVER_ARRIVED,
    ] as const

    TOUR_STATUS: any = {
        unassigned: 1,
        assigned: 2,
        accepted: 3,
        executing: 4,
        complete: 5,
        draft: 6
    } as const;

    PLAN_STATUS: any = {
        unassigned: 1,
        assigned: 2,
        executing: 3,
        complete: 4,
        draft: 5
    } as const;

    ALL_ON_PLAN_STATUS: any = [
        this.PLAN_STATUS.unassigned,
        this.PLAN_STATUS.assigned,
        this.PLAN_STATUS.executing,
        this.PLAN_STATUS.draft,

    ] as const;

    ALL_ON_ROUTE_EDITABLE = [
        this.TASK_STATUS.UNASSIGNED,
        this.TASK_STATUS.ASSIGNED,
        this.TASK_STATUS.ACCEPTED,
        this.TASK_STATUS.DRIVER_START_TO_PICKUP,
        this.TASK_STATUS.DRIVER_ARRIVED_PICKUP,
        this.TASK_STATUS.COMPLETED_PICKUP,
        this.TASK_STATUS.DRIVER_START_TO_DROPOFF,
        this.TASK_STATUS.DRIVER_ARRIVED,
        this.TASK_STATUS.USER_CANCELLED,
        this.TASK_STATUS.DRIVER_CANCELLED,
        this.TASK_STATUS.MISS_DELIVERY_TASKS,
    ] as const

    //! =============================================== new mqtt status flag ===========================
    MQTT_STATUS_FLAG: any = {
        TASK_CREATED: 101,
        TASK_UPDATED: 102,
        TASK_DELETED: 103,
        TASK_ASSIGN_TO_DRIVER: 104,
        TASK_REMOVE_FROM_DRIVER: 105,
        TASK_CREATED_IN_BULK: 106,
        TASK_ACCEPTED_BY_DRIVER: 107,
        TASK_STARTED_BY_DRIVER: 108,
        TASK_COMPLETED: 109,
        TASK_CANCEL_BY_DRIVER: 110,
        TASK_CANCEL_BY_ADMIN: 111,
        TASK_SEQUENCES_SHUFFLED: 112,
        TASK_ASSIGN_INTO_PLANNING: 113,
        TASK_ASSIGN_INTO_PLANNED: 114,
        TASK_STATUS_CHANGE: 115,
        TASK_MISS_DELIVERY: 116,
        ADMIN_CREATED: 201,
        ADMIN_UPDATED: 202,
        ADMIN_DELETED: 203,
        DISPATCHER_CREATED: 301,
        DISPATCHER_UPDATED: 302,
        DISPATCHER_DELETED: 303,
        TEAM_CREATED: 401,
        TEAM_UPDATED: 402,
        TEAM_DELETED: 403,
        HUB_CREATED: 501,
        HUB_UPDATED: 502,
        HUB_DELETED: 503,
        DRIVER_CREATED: 601,
        DRIVER_UPDATED: 602,
        DRIVER_DELETED: 603,
        DRIVER_ONLINE: 604,
        DRIVER_OFFLINE: 605,
        DRIVER_ARRIVED_AT_PICKUP: 607,
        DRIVER_ARRIVED_AT_DROP_OFF: 608,
        DRIVER_GO_HOME: 610,
        DRIVER_ALL_COUNTS: 611,
        DRIVER_VERIFIED: 612,
        DRIVER_UNVERIFIED: 613,
        DRIVER_WARNING: 614,
        DRIVER_BREAK_START: 615,
        DRIVER_BREAK_OFF: 616,
        DRIVER_BULK_UPLOAD: 620,
        VEHICLE_ACTIVE: 701,
        VEHICLE_INACTIVE: 702,
        VEHICLE_ASSIGN: 703,
        VEHICLE_UNASSIGN: 704,
        TOUR_ACCEPTED_BY_DRIVER: 901,
        TOUR_STARTED_BY_DRIVER: 902,
        TOUR_ASSIGNED_TO_DRIVER: 903,
        TOUR_DELETED: 906,
        TOUR_COMPLETED: 907,
        TOUR_UNASSIGNED_FROM_DRIVER: 904,
        PLAN_CREATE: 1101,
        PLAN_UPDATE: 1102,
        PLAN_DELETE: 1103,
        PLAN_CHANGE_TO_DRAFT: 1104,
        JOB_STARTED_BY_DRIVER: 1001,
        JOB_ARRIVED_AT_LOCATION: 1002,
        JOB_COMPLETED: 1003,
        JOB_DELETED: 1004,
        JOB_SEQUENCES_SHUFFLED: 1005,
        JOB_ALLOCATE_TO_TOUR: 1006,
        // CHAT MQTT
        TASK_CHAT_SEND_BY_DRIVER: 803,
        MESSAGE_READ: 805

    } as const;


    // const DRIVER_STATUS = {
    //   offline: 0,
    //   online: 1,
    //   onTrip: 2,
    //   free: 3,
    //   goHomeOn: 1,
    //   goHomeOff: 0
    // };



    driverStatus = {
        'OFFLINE': 0,
        'ONLINE': 1,
        'ON_ROUTE': 2,
        'FREE': 3,
        'GO_HOME': 7,
        'ALL_DRIVERS': -1
    } as const;

    filterFreeDriverStatus = [
        this.driverStatus.ONLINE,
        this.driverStatus.FREE
    ] as const;

    filterAllDriverStatus = [
        this.driverStatus.ONLINE,
        this.driverStatus.FREE,
        this.driverStatus.GO_HOME,
        this.driverStatus.OFFLINE,
        this.driverStatus.ON_ROUTE
    ] as const;


    newDriverStatus = {
        'Offline': 0,
        'Online': 1,
        'On Route': 2,
        'Free': 3
    } as const;



    deleteType = {
        Admin: "Admin",
        Dispatcher: "Dispatcher",
        Team: "Team",
        Hub: "Hub",
        Driver: "Driver",
        Task: "Task",
        ApiKey: "ApiKey",
        Vehicle: "Vehicle",
        Zone: "Zone",
        Shift: "Shift",
        Restaurant: "Restaurant",
        SurgeZone: "Surge Zone",
        AreaZone: "Area Zone",
        TaskCancel: "Task Cancel",
        JobCancel: "Job"
    } as const;

    // ! ---- this is for tour status
    tourStatusMetaData: any = [
        {
            tourStatus: 1,
            status: 'Unassigned',
            gradient: 'background-color: #dcdddf;color:#000',
            color: '#dcdddf',
        },
        {
            tourStatus: 2,
            status: 'Assigned',
            gradient: 'background-color: #4643e8;',
            color: '#4643e8',
        },
        {
            tourStatus: 3,
            status: 'Accepted',
            gradient: 'background-color: #439aea;',
            color: '#439aea',
        },
        {
            tourStatus: 4,
            status: 'Executing',
            gradient: 'background-color: #fa71cd;',
            color: '#fa71cd',
        },
        {
            tourStatus: 5,
            status: 'Completed',
            gradient: 'background-color: #c471f5;',
            color: '#c471f5',
        },
        {
            tourStatus: 6,
            status: 'Draft',
            gradient: 'background-color: #8471f5',
            color: '#8471f5',
        },

    ] as const

    taskMetadata = [
        {
            taskStatus: 1,
            status: 'Unassigned',
            gradient: 'background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0,0,0,0.02) 50%, rgba(255,255,255,0.02) 61%, rgba(0,0,0,0.02) 73%), linear-gradient(33deg, rgba(255,255,255,0.20) 0%, rgba(0,0,0,0.20) 100%); background-blend-mode: normal,color-burn;color:#000',
            // gradient: 'background-color: #dcdddf;color:#000',
            color: 'color:#dcdddf',
            statusIcon: ''
        },
        {
            taskStatus: 2,
            status: 'Assigned',
            // gradient: 'background-color: #21D4FD; background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);',
            gradient: 'background-color: #439aea;',
            color: 'color: #439aea',
            statusIcon: '../../../assets/task_markers/task_assign.png'
        },
        {
            taskStatus: 3,
            status: 'Accepted',
            // gradient: 'background-color: #0093E9; background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);',
            gradient: 'background-color: #6ee7e6;',
            color: 'color: #6ee7e6',
            statusIcon: '../../../assets/task_markers/task_accept.png'
        },
        {
            taskStatus: 4,
            status: 'Driver Start To Pickup',
            // gradient: 'background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);',
            gradient: 'background-color: #fa71cd;',
            color: 'color: #fa71cd',
            statusIcon: '../../../assets/task_markers/task_pickup_start.png'
        },
        {
            taskStatus: 5,
            status: 'Driver Arrived At Pickup',
            // gradient: 'background-color: #FF9A8B; background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%); ',
            gradient: 'background-color: #879D0D;',
            color: 'color: #879D0D',
            statusIcon: '../../../assets/task_markers/task_pickup_arrived.png'
        },
        {
            taskStatus: 6,
            status: 'Driver Completed Pickup',
            // gradient: 'background-image: linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%); ',
            gradient: 'background-color: #8471f5',
            color: 'color: #8471f5',
            statusIcon: '../../../assets/task_markers/task_pickup_compete.png'
        },
        {
            taskStatus: 7,
            status: 'Driver Start To Dropoff',
            // gradient: 'background-color: #A9C9FF; background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);',
            gradient: 'background-color: #dc8300',
            color: 'color: #dc8300',
            statusIcon: '../../../assets/task_markers/task_drop_start.png'
        },
        {
            taskStatus: 8,
            status: 'Driver Arrived At Dropoff',
            // gradient: 'background-image: linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%);',
            gradient: 'background-color: #11aba1',
            color: 'color:#11aba1 ',
            statusIcon: '../../../assets/task_markers/task_drop_arrived.png'
        },
        {
            taskStatus: 9,
            status: 'Completed',
            gradient: 'background-image: linear-gradient(45deg, #00b553 0%, #9dd113 100%);',
            // gradient: 'background-color: #01be7f;',
            color: 'color: #01be7f ',
            statusIcon: '../../../assets/task_markers/task_completed.png'

        },
        {
            taskStatus: 10,
            status: 'User Cancelled',
            // gradient: ' background-image: linear-gradient(147deg, #800000  0%, #FF2525 74%)',
            gradient: 'background-color: #eb4245',
            color: 'color: #eb4245',
            statusIcon: ''

        },
        {
            taskStatus: 11,
            status: 'Driver Cancelled',
            // gradient: ' background-image: linear-gradient(147deg, #800000  0%, #FF2525 74%)',
            gradient: 'background-color: #eb4245;',
            color: 'color: #eb4245',
            statusIcon: ''

        },
        {
            taskStatus: 12,
            status: 'Planning',
            // gradient: 'background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);',
            gradient: 'background-color: #ecd145;',
            color: 'color: #ecd145',
            statusIcon: ''

        },
        {
            taskStatus: 13,
            status: 'Planned',
            // gradient: 'background-color: #FAD961; background-image: linear-gradient(90deg, #F76B1C 0%, #FAD961 100%); color:black;',
            gradient: 'background-color: #6e5ae9;',
            color: 'color: #6e5ae9',
            statusIcon: ''

        },
        {
            taskStatus: 14,
            status: 'Re-scheduled Delivery',
            // gradient: 'background-color: #FAD961; background-image: linear-gradient(90deg, #F76B1C 0%, #FAD961 100%); color:black;',
            gradient: 'background-color: #9967DD;',
            color: 'color: #9967DD',
            statusIcon: '../../../assets/task_markers/miss_delivery.png'

        }
    ] as const

    // ! ---- this is for plan status
    planStatusMetadata = [
        {
            taskStatus: 1,
            status: 'Unassigned',
            gradient: '#dcdddf',
            color: 'rgb(47 49 53)',
            border: '3px solid rgb(105 108 114)',
        },
        {
            taskStatus: 2,
            status: 'Assigned',
            gradient: '#4643e8',
            color: '#fff',
            border: '4px solid rgb(14 11 137)',
        },
        {
            taskStatus: 3,
            status: 'Executing',
            gradient: '#439aea',
            color: '#fff',
            border: '4px solid #439aea',
        },
        {
            taskStatus: 4,
            status: 'Complete',
            gradient: '#01be7f',
            color: '#fff',
            border: '4px solid rgb(11 102 71)',
        },
        {
            taskStatus: 5,
            status: 'Draft',
            gradient: '#f9d423',
            color: '#fff',
            border: '4px solid rgb(172 144 10)',
        }
    ] as const

    driverStatusCount: any = {
        allDriver: 0,
        onlineDriver: 0,
        offlineDriver: 0,
        onRouteDriver: 0,
        freeDriver: 0,
        inactiveDriver: 0,
        activeDriver: 0,
    } as const;

    newDriverVerifiedStatus = {
        'Not Verified': 0,
        'Pending Verified': 1,
        'Verified': 2,
    }

    driverStatusColor = {
        'background-color: #FF0000;color: #f8f3f8;': 0,
        'background-color: #f29c00;color: #ffffff;': 1,
        'background-color: #53b84d;color: #ffffff;': 2,
    } as const

    // ! ----- new plan status code ------------
    newPlanStatus: any = {
        unassigned: 1,
        assigned: 2,
        executing: 3,
        complete: 4,
    } as const

    getTourStatusByKey(status: number): any {
        return this.tourStatusMetaData.find((element: any) => element.tourStatus == status)
    }

    getTaskDetailsByKey(status: number): any {
        return this.taskMetadata.find((taskElement: any) => taskElement.taskStatus == status)
    }

    getPlanDetailsByKey(status: number): any {
        return this.planStatusMetadata.find((taskElement: any) => taskElement.taskStatus == status)
    }

    getUserPhoneNumber(data: any) {
        // console.log("Phone number:", data);

        if (!data) {
            return '';
        }

        let { dialCode, number } = data;

        // Remove the leading "+" character from the dial code
        if (dialCode.startsWith('+')) {
            dialCode = dialCode.substring(1);
        }

        // Remove leading "0" from the phone number
        if (number.startsWith('0')) {
            number = number.substring(1);
        }

        // Remove any spaces from the phone number
        number = number.replace(/ /g, '');

        return `${dialCode} ${number}`;
    }

    secondsToHourAndMinutes(d: number): string {
        let hour = Math.floor(d / 3600);
        let minute = Math.floor(d % 3600 / 60);
        let hourDisplay = hour > 0 ? hour + (hour == 1 ? " hour, " : " hours, ") : '';
        let minuteDisplay = minute > 0 ? minute + (minute == 1 ? " minute" : " minutes") : '0';
        return hourDisplay + minuteDisplay;
    }

    removeObjectInArray(array: any[], where: any[]) {
        let objIndex = array.findIndex((obj => obj[where[0]] == where[1]))
        if (objIndex >= 0) {
            array.splice(objIndex, 1);
        }
        return array;
    }

    updateArrayWithObject(array: any[], where: any[], setData: { [s: string]: unknown; } | ArrayLike<unknown>) {
        // console.log("===== call updateArrayWithObject");
        let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));
        if (objIndex >= 0) {
            for (const [k, v] of Object.entries(setData)) {
                array[objIndex][k] = v;
            }
        }
        return array;
    }

    getTaskDelayColorByMinutes(minutes: any): any {
        // console.log(' :::: getTaskDelayColorByMinutes(minutes) ::::::', minutes);
        if (minutes < 0 && minutes >= environment.taskDelayObject.minAssignTime) {
            // console.log('::: minutes < 0 && minutes >= environment.taskDelayObject.minAssignTime MIN :::: ')
            return environment.taskDelayObject.minDelay
        }
        if (minutes <= 0 && minutes <= environment.taskDelayObject.minAssignTime) {
            // console.log('::: minutes < 0 && minutes >= environment.taskDelayObject.minAssignTime MIN :::: ')
            return environment.taskDelayObject.aot
        }
        if (minutes >= 0) {
            // console.log('::: minutes > 0 MAX :::: ')
            return environment.taskDelayObject.maxDelay
        }

    }

    getDriverStatusByKey(status: number): any {
        for (const [key, value] of Object.entries(this.newDriverVerifiedStatus)) {
            if (value == status) {
                return { key: key, value: value }
            }
        }
    }

    getDriverColorByKey(status: number): any {
        for (let [colorKey, colorValue] of Object.entries(this.driverStatusColor)) {
            if (colorValue == status) {
                return { status: this.getDriverStatusByKey(status), color: colorKey }
            }
        }
    }
    private initIdexDBDatabase(): void {
        const request = window.indexedDB.open(this.dbName, 1);

        request.onerror = (event: any) => {
            console.error('Failed to open database:', event.target.errorCode);
        };

        request.onupgradeneeded = (event: any) => {
            const db = event.target.result;

            // Create an object store (if it doesn't exist)
            if (!db.objectStoreNames.contains(this.objectStoreName)) {
                const objectStore = db.createObjectStore(this.objectStoreName, { keyPath: 'key' });
                objectStore.createIndex('valueIndex', 'value', { unique: false });
            }
        };
    }

    public async storeData(key: string, value: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(this.dbName);

            request.onsuccess = (event: any) => {
                const db = event.target.result;
                const transaction = db.transaction(this.objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(this.objectStoreName);

                const data = { key: key, value: value };
                const addRequest = objectStore.add(data);

                addRequest.onsuccess = () => {
                    resolve();
                };

                addRequest.onerror = (event: any) => {
                    reject(event.target.error);
                };
                db.close();
            };

            request.onerror = (event: any) => {
                reject(event.target.errorCode);
            };
        });
    }

    public async retrieveData(key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(this.dbName);

            request.onsuccess = (event: any) => {
                const db = event.target.result;
                const transaction = db.transaction(this.objectStoreName, 'readonly');
                const objectStore = transaction.objectStore(this.objectStoreName);

                const getRequest = objectStore.get(key);

                getRequest.onsuccess = () => {
                    const retrievedData = getRequest.result ? getRequest.result.value : null;
                    resolve(retrievedData);
                };

                getRequest.onerror = (event: any) => {
                    reject(event.target.error);
                };

                db.close();
            };

            request.onerror = (event: any) => {
                reject(event.target.errorCode);
            };
        });
    }

    driverCounts(counts: any) {
        // console.log('***** counts *****', counts);
        this.driverStatusCount.allDriver = counts.totalCount;
        this.driverStatusCount.onlineDriver = counts.onlineCount;
        this.driverStatusCount.offlineDriver = counts.offlineCount;
        this.driverStatusCount.onRouteDriver = counts.onTripCount;
        this.driverStatusCount.freeDriver = counts.freeCount;
        this.driverStatusCount.inactiveDriver = counts.notVerifiedCount;
        this.driverStatusCount.activeDriver = counts.verifiedCount;
    }

    lineClamp(webkit_line_clamp: any, width: any) { // d-flex you can not use when you use this function 
        return {
            'display': '-webkit-box',
            'overflow': 'hidden',
            'width': `${width}px`,
            'text-overflow': ' ellipsis',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': `${webkit_line_clamp}`,
        }
    }
    driverMarkerIcon: any = {
        scaledSize: {
            width: 75,
            height: 50
        },
        labelOrigin: { x: 22, y: 50 },
    };
    driverMarkerLabel: any = {
        color: "blueviolet",
        fontFamily: "",
        fontSize: "14px",
        fontWeight: "600",
        text: "",
        isShow: true
    };


    setMarkerObj(driverData: any) {
        let markerObj: any = {
            driverId: driverData.driverId,
            latLong: driverData.latLong,
            driverName: driverData.driverName,
            taskStatusFlag: driverData.taskStatusFlag,
            driverStatus: driverData.driverStatus,
            driverEmployeeId: driverData.driverEmployeeId,
            taskCount: driverData.taskCount,
            infoWindowIsOpen: false,
            icon: {
                url: this.setDriverMarkerIcons(driverData),
                scaledSize: {
                    height: this.driverMarkerIcon.scaledSize.height,
                    width: this.driverMarkerIcon.scaledSize.width
                },
                labelOrigin: this.driverMarkerIcon.labelOrigin,
            },
        }
        if (this.driverMarkerLabel.isShow == true) {
            markerObj.label = {
                color: this.driverMarkerLabel.color,
                fontFamily: this.driverMarkerLabel.fontFamily,
                fontSize: this.driverMarkerLabel.fontSize,
                fontWeight: this.driverMarkerLabel.fontWeight,
                text: driverData.driverName
            }
        }
        return markerObj;
    }


    setDriverMarkerIcons(driverData: any) {
        console.log('****** setDriverMarkerIcons driverData ******', driverData);
        let marker: string = "";
        if (driverData.taskStatusFlag > 0 && this.ALL_TASKS_MARKERS.includes(driverData.taskStatusFlag)) {
            if (driverData.taskStatusFlag == this.TASK_STATUS.ACCEPTED) {
                marker = "../../assets/driver_markers/accept.png"
                console.log('*****>>> accept icon <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.ASSIGNED) {
                marker = "../../assets/driver_markers/assign.png"
                console.log('*****>>> assign task icon <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.DRIVER_START_TO_PICKUP) {
                marker = "../../assets/driver_markers/pickup_start.png"
                console.log('*****>>> pick up start <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.DRIVER_ARRIVED_PICKUP) {
                marker = "../../assets/driver_markers/pickup_arrived.png"
                console.log('*****>>> arrived at pickup <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.COMPLETED_PICKUP) {
                marker = "../../assets/driver_markers/pickup_compete.png"
                console.log('*****>>> complete pickup task <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.DRIVER_START_TO_DROPOFF) {
                marker = "../../assets/driver_markers/drop_start.png"
                console.log('*****>>> drop off start <<<******', marker);
            }
            else if (driverData.taskStatusFlag == this.TASK_STATUS.DRIVER_ARRIVED) {
                marker = "../../assets/driver_markers/drop_arrived.png"
                console.log('*****>>> arrived at drop off <<<******', marker);
            }
        }
        else {
            marker = "../../assets/driver_markers/free.png"
        }
        console.log('*****>>> return marker <<<******', marker);
        return marker
    }

    getDriverStatusColorCode(data: any) {
        let colorCode = {};

        if (data.status == 1) {
            if (data.statusChangedBy == 5 && (data.driverWarningPopupNumber == undefined || data.driverWarningPopupNumber == 0)) {
                colorCode = {
                    'background-color': 'rgb(209, 250, 229)', // green
                    'color': 'rgb(16, 185, 129)',
                    'border-color': 'rgb(16, 185, 129)',
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }
            }
            if (data.statusChangedBy == 6 && data.driverWarningPopupNumber > 0) {
                colorCode = {
                    'background-color': ' rgb(255, 237, 213)', // yellow
                    'color': ' rgb(154, 52, 18)',
                    'border-color': ' rgb(154, 52, 18)',
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }
            }
        }

        if (data.status == 0) {
            if (data.statusChangedBy == 5) {
                colorCode = {
                    'background-color': 'rgb(209, 232, 250)', // blue
                    'color': 'rgb(3, 102, 214)',
                    'border-color': 'rgb(3, 102, 214)',
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }
            }
            if (data.statusChangedBy == 6) {
                colorCode = {
                    'background-color': ' rgb(255, 237, 213)', // yellow
                    'color': ' rgb(154, 52, 18)',
                    'border-color': ' rgb(154, 52, 18)',
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }
            }
            if (data.statusChangedBy == 3) {
                colorCode = {
                    'background-color': 'rgb(255, 229, 229)', // red
                    'color': 'rgb(185, 28, 28)',
                    'border-color': 'rgb(185, 28, 28)',
                    'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }
            }

        }
        return colorCode
    }

    getDriverBreakStatusColorCode(data: any) {
        let colorCode = {}
        if (data.status == 2) {
            colorCode = {
                'background-color': 'rgb(209, 250, 229)',
                'color': 'rgb(16, 185, 129)',
                'border-color': 'rgb(16, 185, 129)',
                'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
            }
        }
        if (data.status == 3) {
            colorCode = {
                'background-color': 'rgb(209, 232, 250)', // blue
                'color': 'rgb(3, 102, 214)',
                'border-color': 'rgb(3, 102, 214)',
                'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
            }
        }
        if (data.status == 3 && data.statusChangedBy == 6) {
            colorCode = {
                'background-color': 'rgb(255, 229, 229)', // red
                'color': 'rgb(185, 28, 28)',
                'border-color': 'rgb(185, 28, 28)',
                'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
            }
        }
        return colorCode
    }

    chatNotificationCount: number = 0

    trimFormControlValues(controls: any): any {
        if (Array.isArray(controls)) {
            return controls.map((control: any) => this.trimFormControlValues(control));
        } else if (typeof controls === 'object' && controls !== null) {
            const trimmedControls: { [key: string]: any } = {};
            Object.keys(controls).forEach(key => {
                if (controls.hasOwnProperty(key)) {
                    trimmedControls[key] = this.trimFormControlValues(controls[key]);
                }
            });
            return trimmedControls;
        } else if (typeof controls === 'string') {
            return controls.trim();
        } else {
            return controls;
        }
    }

    // Helper method to get keys of an object
    getObjectKeys(obj: any): string[] {
        return obj && Object.keys(obj);
    }

    getTeams() {
        this.CallMethod.getAllTeams({}).subscribe(
            (result: any) => {
                this.dataShareService.teamsArrayDataShareService = result.status ? result.data : [];
                this.dataShareService.shareData({ function: 'BULK_UPLOAD_TEAM_SUCCESS', data: result.data });
            },
        );

    }

}


