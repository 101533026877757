import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable, Observer, Subject, Subscription, fromEvent, interval, merge, of, takeUntil } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiCallService } from './Services/api-call.service';
import { DataShareService } from './Services/data-share.service';
import { GlobalService } from './Services/global.service';
import { IMqttMessage, MqttService } from 'ngx-mqtt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {

  private unsubscribe$ = new Subject<void>();
  conn = (navigator as any).connection;
  isInternetReload: boolean = false;
  hasUpdate: boolean = false;
  updateIntervalSubscription: Subscription | undefined;
  updateAvailableSubscription: Subscription | undefined;


  constructor(
    public Global: GlobalService,
    public shareData: DataShareService,
    private renderer: Renderer2,
    private primengConfig: PrimeNGConfig,
    private callMethod: ApiCallService,
    public toaster: MessageService,
    public title: Title,
    private swUpdate: SwUpdate,
  ) {

    this.renderer.listen('window', 'click', (e: Event) => {
      // console.log('****** e ******', e);
      this.Global.renderData = e;
      this.shareData.shareData({ function: 'renderData' });
    });

    this.createOnline$().subscribe(isOnline => {
      if (isOnline) {
        // console.log('****** this.conn.effectiveType ******', this.conn.effectiveType);
        if (this.conn.effectiveType == '4g' || this.conn.effectiveType == '3g') {
          if (this.isInternetReload == true) {
            this.Global.noInternetConnection = "onInternet";
            this.shareData.shareData({ function: 'reloadData', data: "" });
            this.reloadSite();
            setTimeout(() => {
              this.Global.noInternetConnection = "internet";
            }, 3000);

          } else {
            this.Global.noInternetConnection = "internet";
          }
        } else {
          this.Global.noInternetConnection = "noInternet";
          this.isInternetReload = true;
        }
      } else {
        this.Global.noInternetConnection = "noInternet";
        this.isInternetReload = true;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getCompanyDetails();
    // this.subscribeToMQTT();
    this.primengConfig.ripple = true;

    // check for platform update
    if (this.swUpdate.isEnabled) {
      this.updateIntervalSubscription = interval(60000).subscribe(() =>
        this.swUpdate.checkForUpdate().then(() => {
          console.log('Checking for updates...');
        })
      );
    }

    this.updateAvailableSubscription = this.swUpdate.available.subscribe(() => {
      this.hasUpdate = true;
      console.log('An update is available.');
      this.unsubscribe();
    });

    // ------------- Dashboard Details Splitter Size  -------------
    if (this.Global.getLocalStorage({ 'key': 'setCollapse' }) == undefined) {
      this.Global.dashboardPageSplitterSize = environment.dashboardPageSplitterSize;
    } else {
      this.Global.dashboardPageSplitterSize = this.Global.getLocalStorage({ 'key': 'setCollapse' });
    }
  }

  ngAfterViewInit() {
    let loader = this.renderer.selectRootElement('#pre-loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  getCompanyDetails(): Promise<void> {
    return new Promise(resolve => {
      this.Global.setHeaderEncryptData(window.location.host);

      this.callMethod.getCompanyDetails({ "domain": window.location.host }).pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
        if (response.status) {
          this.Global.setLocalStorage({
            'key': 'companyId',
            value: response.data.companyId,
          });
          this.Global.companyDetails.companyId = response.data.companyId;

          let colorCode = response.data.colorCode.split(',');
          this.Global.companyDetails.companyColorCodes = response.data.colorCode;
          this.Global.companyDetails.companyTitle = response.data.pageTitle;
          this.Global.companyDetails.companyDescription = response.data.companyDescription;
          this.Global.companyDetails.companyLogo = environment.baseUrl + environment.imageUrl + environment.CompanySetting + response.data.companyLogo;
          this.Global.companyDetails.companyIcon = environment.baseUrl + environment.imageUrl + environment.CompanySetting + response.data.companyIcon;
          this.Global.companyDetails.companyFavIcon = environment.baseUrl + environment.imageUrl + environment.CompanySetting + response.data.companyFavicon;
          this.Global.companyDetails.companyName = response.data.title;

          this.title.setTitle(response.data.title + ' | ' + response.data.pageTitle)
          let favIcon = document.getElementById('favIcon') as HTMLLinkElement;

          favIcon.setAttribute('href', this.Global.companyDetails.companyFavIcon);
          resolve();
        }
      });
    })
  }
  createOnline$() {
    return merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)), new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
    }));
  }

  reloadSite(): void {
    // localStorage.clear();
    location.reload();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
    this.unsubscribe();
  }

  private unsubscribe(): void {
    if (this.updateIntervalSubscription) {
      this.updateIntervalSubscription.unsubscribe();
    }
    if (this.updateAvailableSubscription) {
      this.updateAvailableSubscription.unsubscribe();
    }
  }

}