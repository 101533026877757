<section class="">
  <div class="bg_image">
    <img src="../../../assets/images/login_page.png" />
  </div>
  <div class="circle"></div>
  <form class="form box_bg_color block" appAutofocusValidation (ngSubmit)="isLoginValid()" [formGroup]="loginForm"
    #form="ngForm">
    <h2 class="form__title "> <img [src]="Global.companyDetails.companyLogo" width="100" alt="" srcset=""> </h2>
    <p class="form__paragraph">Access your {{Global.companyDetails.companyName}} Dashboard</p>
    <!-- <label class="switch" for="themeSwitch" style="position: absolute;
      top: 15px;
      right: 20px;">
          <input type="checkbox" id="themeSwitch" (click)="Global.signinThemeChange()">
          <span class="slider"></span>
      </label> -->
    <div class="custom_input_btn">
      <div class="group formGroup">
        <input type="text" formControlName="email"
          [ngClass]="email.invalid && (email.dirty || email.touched)? 'form-control':'form-control'"
          [pTooltip]="email.invalid && (email.dirty || email.touched) ? 'Enter your user email' : '' "
          tooltipPosition="right" tooltipEvent="focus" required="required" formControlName="email" required
          [class.is-invalid]="email.invalid && (email.dirty || email.touched)" /><span class="highlight"></span><span
          class="bar"></span>
        <label>Email</label>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-left invalid-feedback">
          <div *ngIf="email.errors?.['required']">
            Please fill out this field.
          </div>
          <div *ngIf="email.errors?.['minlength']">
            This field must have at least 1 character.
          </div>
          <div *ngIf="email.errors?.['maxlength']">
            This field must have at most 250 characters.
          </div>
          <div
            *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
            Invalid email format.
          </div>
        </div>
      </div>
      <div class="group">
        <input [type]="Global.fieldTextType ? 'text' : 'password'"
          [ngClass]="password.invalid && (password.dirty || password.touched)? 'form-control':'form-control'"
          autocomplete="off" required="required" id="password" formControlName="password" required
          [class.is-invalid]="password.invalid && (password.dirty || password.touched)" />
        <span class="highlight"></span><span class="bar"></span>
        <label>Password</label>
        <div class="showPassword cursor-pointer form__paragraph box_bg_color">
          <i class="pi pi-eye" (click)="Global.toggleFieldTextType()"
            [ngClass]="{ 'pi-eye-slash': !Global.fieldTextType,'pi-eye': Global.fieldTextType }"></i>
        </div>
        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback text-left">
          <div *ngIf="password.errors?.['required']">
            Please fill out this field.
          </div>
        </div>
      </div>
    </div>

    <div class="flex align-items-center justify-content-between">
      <div class="group ml-2 flex align-items-center">
        <div class="custom_checkbox_remember">
          <label for="rememberMe" class="relative flex align-items-center cursor-pointer  w-full gap-2">
            <input id="rememberMe" [checked]="checked" (click)="rememberMe($event)"
              class="input-checkbox absolute top-1" type="checkbox" />
            <label for="rememberMe" class="checkbox-sm right-2"></label>
            <label for="rememberMe" class="mb-0 form__paragraph flex align-items-center cursor-pointer ">
              Remember me
            </label>
          </label>
        </div>
      </div>
      <div class="group flex align-items-center">
        <a class="cursor-pointer" (click)="gotoForgetPassword(userEmail)">
          Forget password ?
        </a>
      </div>
    </div>
    <div class="group" [ngStyle]="{'margin-top' : loginBtn ? '3rem' : '2rem'}">
      <div class="flex justify-content-center w-100 my-2">
        <div [hidden]="!loginBtn" class="btn_loader"></div>
      </div>
      <button [hidden]="loginBtn" type="submit" pButton pRipple><span>Login</span></button>
    </div>
    <div class="form__paragraph " style="margin-top: 2rem;">
      Don’t have an account? Sign up for a<a class="cursor-pointer" routerLink="/signup"> Free Trial.</a>
    </div>
  </form>
  <div class="circle_bottom"></div>
  <div class="area">
    <ul class="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  <!-- <div class="car_bg"></div> -->
</section>
<p-toast></p-toast>