import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Components
import { ForgetPasswordComponent } from './AuthModule/forget-password/forget-password.component';
import { LoginComponent } from './AuthModule/login/login.component';
import { PageNotFoundComponent } from './AuthModule/page-not-found/page-not-found.component';
import { RegisterComponent } from './AuthModule/register/register.component';
import { ResetPasswordComponent } from './AuthModule/reset-password/reset-password.component';
import { AutofocusValidationDirective } from './Directive/autofocus-validation.directive';
import { EmailValidatorDirective } from './Directive/email-validator.directive';
import { NoRightClickDirective } from './Directive/no-right-click.directive';
import { HttpconfigInterceptor } from './interceptor/interceptor.interceptor';

// Third Party Libraries
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { DataShareService } from './Services/data-share.service';
import { ErrorLoggingService } from './Services/error-logging.service';
import { EventMqttService } from './Services/mqtt-event.service';
import { SubmissionService } from './Services/submission.service';


const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.hostName,
  path: environment.mqtt.path,
  protocol: environment.mqtt.protocol == 'wss' ? 'wss' : 'ws',
  clean: environment.mqtt.clean,
  connectTimeout: environment.mqtt.connectTimeout,
  clientId: environment.mqtt.clientId,
  username: environment.mqtt.userName,
  password: environment.mqtt.password,
  reconnectPeriod: environment.mqtt.reconnectPeriod
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    EmailValidatorDirective,
    PageNotFoundComponent,
    NoRightClickDirective,
    AutofocusValidationDirective,
    // PasswordMatchDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientJsonpModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 10 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000',
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    StepsModule,
    ToastModule,
    DropdownModule,
    PasswordModule,
    DialogModule,
    MatStepperModule,
    DragDropModule,
    MatMomentDateModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
  ],
  providers: [
    MessageService,
    DataShareService,
    EventMqttService,
    SubmissionService,
    ErrorLoggingService,
    MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ResponseInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }