import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subject, Subscription } from "rxjs";
import { CustomValidators } from "src/app/Directive/CustomValidators";
import { ApiCallService } from "src/app/Services/api-call.service";
import { DataShareService } from "src/app/Services/data-share.service";
import { GlobalService } from "src/app/Services/global.service";
import { ActivatedRoute } from "@angular/router";

interface IOTP {
  otp: number;
}

interface IchangePassword {
  password: string;
  confirmPassword: string;
}

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private subscription: Subscription = new Subscription();

  @ViewChild("stepper")
  private stepper!: MatStepper;

  checkOtp: IOTP;
  changeUserPassword: IchangePassword;
  resetPasswordForm!: FormGroup;
  OTPForm!: FormGroup;
  fetchUserId: string = "";
  linear: boolean = true;
  isFirstLogin: boolean = false;
  encryptedEmail: string = "";
  decryptedEmail: string = "";
  constructor(
    public toaster: MessageService,
    public Global: GlobalService,
    private callMethod: ApiCallService,
    private router: Router,
    public dataShareService: DataShareService,
    public activatedRoute: ActivatedRoute
  ) {
    this.checkOtp = {} as IOTP;
    this.changeUserPassword = {} as IchangePassword;
  }

  forgetUserId: any = "";

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.forgetUserId = this.Global.getLocalStorage({ key: "userId" });
    this.Global.setLocalStorage({
      key: "forgetUserId",
      value: this.forgetUserId,
    });
    console.log("***** Global this.forgetUserId *****", this.forgetUserId);
    let isFirstLogins = this.Global.getLocalStorage({
      key: "isFirstLogin",
    });
    if (isFirstLogins == 1) {
      this.isFirstLogin = true;
    }
    this.subscription = this.dataShareService
      .getData()
      .subscribe((result: any) => {
        // console.log("this.dataShareService.getData().subscribe((result: any) ::::::::", result);

        if (result.function == "getUserId") {
          this.forgetUserId = result.data;
          console.log(
            "*****  this.Global.userDetails.userId *****",
            this.forgetUserId
          );
        }
      });

    this.timeLeft = this.Global.getLocalStorage({
      key: "otpTime",
    });
    if (this.timeLeft > 0) {
      this.resendOtp = true;
      this.startTimer();
    }

    this.interval = this.Global.getLocalStorage({
      key: "interval",
    });

    this.OTPForm = new FormGroup({
      otp: new FormControl("", [Validators.required]),
    });

    this.resetPasswordForm = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"
        ),
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.encryptedEmail = params["resetEmail"];
      this.decryptedEmail = this.Global.decryptData(this.encryptedEmail);
      console.log("Decrypted User Email", this.decryptedEmail);
    });
  }

  get otp() {
    return this.OTPForm.get("otp")!;
  }

  get password() {
    return this.resetPasswordForm.get("password")!;
  }

  get confirmPassword() {
    return this.resetPasswordForm.get("confirmPassword")!;
  }

  timeLeft: any = 30;
  interval: any;
  disabled: boolean = false;

  pauseTimer() {
    clearInterval(this.interval);
  }

  validate(string: any) {
    if (string == "OTP") {
      if (this.OTPForm.invalid) {
        for (const control of Object.keys(this.OTPForm.controls)) {
          this.OTPForm.controls[control].markAsTouched();
        }
        return;
      } else {
        this.otpVerify();
      }
    } else if (string == "changePassword") {
      if (this.resetPasswordForm.invalid) {
        for (const control of Object.keys(this.resetPasswordForm.controls)) {
          this.resetPasswordForm.controls[control].markAsTouched();
        }
        this.changeUserPassword = this.resetPasswordForm.value;
        return;
      } else {
        this.resetPassword();
      }
    }
  }

  btnLoader: boolean = false;
  otpVerify() {
    console.log("this.forgotUserId", this.forgetUserId);
    let otpReqObj = {
      userId: this.Global.getLocalStorage({ key: "forgetUserId" }),
      // userId: this.Global.userDetails.userId ? this.Global.userDetails.userId : this.Global.getLocalStorage({ key: 'userId' }),
      otp: this.OTPForm.value.otp,
    };
    console.log("***** otprReqObj *****", otpReqObj);
    this.btnLoader = true;
    let merge =
      this.OTPForm.value.otp +
      this.Global.getLocalStorage({ key: "forgetUserId" }) +
      this.Global.getLocalStorage({ key: "companyId" });
    console.log("***** otp this.forgetUserId *****", this.forgetUserId);
    console.log("::::::::::otp Verify (merge) ::::::::::", merge);

    this.Global.setHeaderEncryptData(merge);

    this.callMethod.sendOTP(otpReqObj).subscribe(
      (result: any) => {
        console.log(
          "********************************** sendOTP() **********************************",
          result
        );
        if (result.status) {
          this.stepper.next();
        } else {
          this.toaster.add({
            severity: "error",
            summary: "Error",
            detail: result.message,
          });
          this.stepper.previous();
        }

        this.btnLoader = false;
      },
      (error) => {
        console.log("===== login call error :: ", error);
      }
    );
  }
  reqObj: any;
  resetPassword() {
    this.btnLoader = true;
    this.changeUserPassword = this.resetPasswordForm.value;
    this.reqObj = {
      userId: this.forgetUserId,
      password: this.changeUserPassword.password,
    };
    if (this.Global.userDetails.isFirstLogin == 1) {
      this.reqObj.isFirstLogin = true;
    }
    if (
      this.changeUserPassword.password ==
      this.changeUserPassword.confirmPassword
    ) {
      console.log("***** this.forgetUserId *****", this.forgetUserId);
      let merge =
        this.changeUserPassword.password +
        this.Global.getLocalStorage({ key: "forgetUserId" }) +
        this.Global.getLocalStorage({ key: "companyId" });
      console.log("::::::::::resetPassword (merge) ::::::::::", merge);
      this.Global.setHeaderEncryptData(merge);

      this.callMethod.resetPassword(this.reqObj).subscribe(
        (result: any) => {
          console.log(
            "********************************** resetPassword() **********************************",
            result
          );
          if (result.status) {
            this.toaster.add({
              severity: "success",
              summary: "Great !",
              detail: "Password Change Successfully",
            });
            this.Global.setLocalStorage({
              key: "JwtToken",
              value: result.token,
            });
            this.Global.setLocalStorage({
              key: "userId",
              value: result.data.userId,
            });
            this.Global.setLocalStorage({
              key: "fullName",
              value: result.data.fullName,
            });
            this.Global.setLocalStorage({
              key: "phone",
              value: result.data.phone,
            });
            this.Global.setLocalStorage({
              key: "email",
              value: result.data.email,
            });
            this.Global.setLocalStorage({
              key: "type",
              value: result.data.type,
            });
            this.Global.setLocalStorage({
              key: "isFirstLogin",
              value: result.data.isFirstLogin,
            });
            setTimeout(() => {
              this.btnLoader = false;
              this.router.navigate(["dashboard"]);
            }, 2000);
          } else {
            this.toaster.add({
              severity: "error",
              summary: "Error",
              detail: result.message,
            });
          }
        },
        (error) => {
          console.log("===== login call error :: ", error);
        }
      );
    } else {
      this.btnLoader = false;
      this.toaster.add({
        severity: "error",
        summary: "Error",
        detail: "Password not Match !",
      });
    }
  }

  resendOtp: boolean = false;
  otpTimer: boolean = true;
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.Global.setLocalStorage({
          key: "otpTime",
          value: this.timeLeft,
        });
      } else {
        this.timeLeft = 30;
      }
      if (this.timeLeft < 10) {
        this.timeLeft = "0" + this.timeLeft;
      }

      if (this.timeLeft == 0) {
        this.resendOtp = false;
        this.Global.removeLocalStorage({ key: "otpTime" });
        // this.otpTimer = false
        console.log("finished");
        clearInterval(this.interval);
      }
    }, 1000);
  }

  forgotPassword() {
    this.btnLoader = true;

    let reqObj = {
      email: this.Global.userDetails.email || this.decryptedEmail,
    };

    let merge = reqObj.email + this.Global.companyDetails.companyId;
    this.Global.setHeaderEncryptData(merge);

    this.callMethod.forgotPassword(reqObj).subscribe((result: any) => {
      console.log(
        "********************************** forgotPassword() **********************************",
        result
      );
      if (result.status) {
        this.toaster.add({
          severity: "success",
          summary: "Great !",
          detail: "OTP Send Successfully To Your Email",
        });
        this.resendOtp = true;
        this.startTimer();
        this.Global.setLocalStorage({
          key: "userId",
          value: result.userId,
        });
      } else {
        this.toaster.add({
          severity: "error",
          summary: "Error",
          detail: result.message,
        });
      }
      this.btnLoader = false;
    });
  }
  // isFirstLogin(){
  //   this.stepper.next();

  // }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
