import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";



@Injectable({
  providedIn: "root",
})
export class DataShareService {
  teamsArrayDataShareService: any[] = [];
  driversArrayDataShareService: any[] = [];
  hubsArrayDataShareService: any[] = [];
  taskArrayDataShareService: any[] = [];
  adminArrayDataShareService: any[] = [];
  dispatcherArrayDataShareService: any[] = [];
  apiKeysArrayDataShareService: any[] = [];
  WebhookArrayDataShareService: any[] = [];
  vehicleBrandArrayDataShareService: any[] = [];
  vehicleModelArrayDataShareService: any[] = [];
  vehicleArrayDataShareService: any[] = [];
  carTypeArrayDataShareService: any[] = [];
  driverShiftArrayDataShareService: any[] = [];
  hubZoneArrayDataShareService: any[] = [];
  serviceZoneArrayDataShareService: any[] = [];
  branchArrayDataShareService: any[] = [];
  surgeZoneArrayDataShareService: any[] = [];
  areaZoneArrayDataShareService: any[] = [];
  planCreatedArrayDataShareService: any[] = [];
  planCreatedFromTaskArrayDataShareService: any[] = [];

  constructor() {}

  private subject = new Subject<any>();

  shareData(data: any) {
    this.subject.next(data);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}

