import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './AuthModule/forget-password/forget-password.component';
import { LoginComponent } from './AuthModule/login/login.component';
import { PageNotFoundComponent } from './AuthModule/page-not-found/page-not-found.component';
import { RegisterComponent } from './AuthModule/register/register.component';
import { ResetPasswordComponent } from './AuthModule/reset-password/reset-password.component';
import { AuthGuard } from './Shared/auth.guard';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "signup", component: RegisterComponent },
  { path: "forget-password", component: ForgetPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: '404', component: PageNotFoundComponent },
  // {path: '**', redirectTo: '/404'},
  {
    path: "dashboard", loadChildren: () => import('./DashboardModule/dashboard-module-routing/dashboard-module-routing.module').then(m => m.DashboardModuleRoutingModule), canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
