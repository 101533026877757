<p-toast></p-toast>
<section class="login">
    <div class="login_box box_bg_color">
        <div class="right">
            <div class="right-text">
                <h2><img [src]="Global.companyDetails.companyIcon" width="50" alt="" srcset=""> </h2>
                <h5>{{ Global.companyDetails.companyTitle }}</h5>
                <h5 class="p-4">{{ Global.companyDetails.companyDescription }}</h5>
            </div>
            <div class="right-inductor"><img loading="lazy" src="" alt=""></div>
        </div>
        <div class="left">
            <div class="top_link">
            </div>
            <div class="contact">
                <p-steps [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
                <div class="form-container">
                    <form [formGroup]="personalForm" autocomplete="off" appAutofocusValidation
                        (ngSubmit)="validate('personalForm')" #form="ngForm">
                        <div class="formGroup" *ngIf="page1">
                            <div class="custom_input_btn wrapper">
                                <div class="mt-4">
                                    <h3 class="text-gray-400">Create an account</h3>
                                    <h5 class="text-gray-400">Personal info</h5>
                                </div>
                                <div class="" autocomplete="off">
                                    <div class="flex ">
                                        <div class="formGroup group ">
                                            <input type="text" class="capitalization" autocomplete="off"
                                                required="required"
                                                [ngClass]="name.invalid && (name.dirty || name.touched)? 'form-control':'form-control'"
                                                spellcheck="false" formControlName="name" required
                                                [class.is-invalid]="name.invalid && (name.dirty || name.touched)" /><span
                                                class="highlight"></span><span class="bar"></span>
                                            <label>Full name</label>
                                            <div *ngIf="name.invalid && (name.dirty || name.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="name.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                                <div *ngIf="name.errors?.['minlength']">
                                                    This field must have at least 1 character.
                                                </div>
                                                <div *ngIf="name.errors?.['maxlength']">
                                                    This field must have at most 250 characters.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group formGroup">
                                            <input type="text" class="capitalization" autocomplete="off"
                                                required="required" spellcheck="false" formControlName="companyName"
                                                [ngClass]="companyName.invalid && (companyName.dirty || companyName.touched)? 'form-control':'form-control'"
                                                [class.is-invalid]="companyName.invalid && (companyName.dirty || companyName.touched)" /><span
                                                class="highlight"></span><span class="bar"></span>
                                            <label>Company name</label>
                                            <div *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="companyName.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="group formGroup">
                                            <input type="text" required="required" autocomplete="off"
                                                formControlName="email" required
                                                [ngClass]="email.invalid && (email.dirty || email.touched)? 'form-control':'form-control'"
                                                [class.is-invalid]="email.invalid && (email.dirty || email.touched)" /><span
                                                class="highlight"></span><span class="bar"></span>
                                            <label>Email</label>
                                            <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="email.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                                <div *ngIf="email.errors?.['minlength']">
                                                    This field must have at least 1 character.
                                                </div>
                                                <div *ngIf="email.errors?.['maxlength']">
                                                    This field must have at most 250 characters.
                                                </div>
                                                <div
                                                    *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
                                                    Invalid email format.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="group formGroup">
                                            <ngx-intl-tel-input id="phone" [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                [phoneValidation]="true" [ariaAutoComplete]="false"
                                                [cssClass]="phoneNumber.invalid && (phoneNumber.dirty && phoneNumber.touched) ? 'form-control ng-invalid is-invalid ng-touched':'form-control'"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                                                [class.is-invalid]="phoneNumber.invalid && (phoneNumber.dirty && phoneNumber.touched)"
                                                [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryFlag"
                                                autocomplete="new-password" required [maxLength]="15"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [pTooltip]="phoneNumber.invalid &&   (phoneNumber.dirty || phoneNumber.touched) ? 'Enter Phone Number' : '' "
                                                [numberFormat]="PhoneNumberFormat.National"
                                                formControlName="phoneNumber">
                                            </ngx-intl-tel-input>
                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="group formGroup">
                                            <input [type]="Global.fieldTextType ? 'text' : 'password'"
                                                required="required" id="password" name="password"
                                                autocomplete="new-password" formControlName="password" required
                                                [ngClass]="password.invalid && (password.dirty || password.touched)? 'form-control':'form-control'"
                                                [class.is-invalid]="password.invalid && (password.dirty || password.touched)" />
                                            <span class="highlight"></span><span class="bar"></span>
                                            <label>Create password</label>
                                            <div class="showPassword cursor-pointer text-black-50 box_bg_color">
                                                <i class="pi pi-eye" (click)="Global.toggleFieldTextType()"
                                                    [ngClass]="{ 'pi-eye-slash': !Global.fieldTextType,'pi-eye': Global.fieldTextType }"></i>
                                            </div>
                                            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="password.errors?.['required']">
                                                    Please fill out this field.
                                                </div>

                                                <div *ngIf="password.errors?.['pattern']">
                                                    Please match the requested format.
                                                </div>
                                            </div>
                                        </div>
                                        <p class="common-Body3 ml-2">Requires at least 8 characters, including two or
                                            more uppercase letters, lowercase letters, numbers, or punctuations</p>
                                    </div>
                                </div>
                                <p class="common-Body3 fullWidth">Already have an account? Log in<a href="/login">
                                        here</a>.</p>
                            </div>
                            <div class="custom_sign_up_btn">
                                <div class="content_btn_section">
                                    <div class="flex justify-content-center w-100 my-2">
                                        <div [hidden]="!loginBtn" class="btn_loader"></div>
                                    </div>
                                    <button [hidden]="loginBtn" class="nav_content_btn nav_content_btn2" type="submit"
                                        pButton pRipple
                                        [pTooltip]="isValidate ? 'Please fill all details to continue' : ''"
                                        tooltipEvent="focus"> Continue<i class="pi pi-arrow-right"
                                            aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="personalCountryForm" autocomplete="off" appAutofocusValidation
                        (ngSubmit)="validate('countryData')" #form="ngForm">
                        <div class="formGroup" id="page_2" *ngIf="page2">
                            <div class="custom_input_btn register_drop_down wrapper">
                                <div class="mt-4">
                                    <h3 class="text-gray-400">Create an account</h3>
                                    <h5 class="text-gray-400">Company info</h5>
                                </div>
                                <div class="w-100" style="min-width: 15.625rem;" autocomplete="off">
                                    <div class="flex">
                                        <div class="group w-100">
                                            <p-dropdown formControlName="countryLists"
                                                (onChange)="selectedCountries(selectedCountry)" [options]="countryList"
                                                [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true"
                                                styleClass="form-control" filterBy="name" [showClear]="false"
                                                placeholder="Select a Country" [required]="true"
                                                [class.is-invalid]="countryLists.invalid && (countryLists.dirty || countryLists.touched)">
                                            </p-dropdown>
                                            <div *ngIf="countryLists.invalid && (countryLists.dirty || countryLists.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="countryLists.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="group w-100">
                                            <p-dropdown formControlName="timeZone" [options]="timeZoneList"
                                                [(ngModel)]="selectedTimeZone" optionLabel="name" [filter]="true"
                                                styleClass="form-control" filterBy="name" [showClear]="false"
                                                placeholder="Select Time Zone" [required]="true">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="country-item country-item-value d-flex"
                                                        style="gap: 10px;" *ngIf="selectedTimeZone">
                                                        <div>{{selectedTimeZone.name}}
                                                            {{selectedTimeZone.dstOffsetStr}} :
                                                            {{selectedTimeZone.utcOffsetStr}}</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="country-item d-flex" style="gap: 20px;">

                                                        <div>{{country.name}} {{country.utcOffsetStr}} :
                                                            {{country.dstOffsetStr}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <div *ngIf="timeZone.invalid && (timeZone.dirty || timeZone.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="countryLists.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="group w-100">
                                            <p-dropdown formControlName="industriesList" [options]="industries"
                                                [(ngModel)]="selectedIndustries" optionLabel="name" [filter]="true"
                                                filterBy="name" [showClear]="false" placeholder="Select a industry"
                                                [required]="true"
                                                [class.is-invalid]="industriesList.invalid && (industriesList.dirty || industriesList.touched)">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="country-item country-item-value"
                                                        *ngIf="selectedIndustries">
                                                        <div>{{selectedIndustries.name}}</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="country-item">
                                                        <div>{{country.name}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <div *ngIf="industriesList.invalid && (industriesList.dirty || industriesList.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="industriesList.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group w-100">
                                            <p-dropdown formControlName="monthlyVolumeList" [options]="monthlyVolume"
                                                [(ngModel)]="selectedMonthlyVolume" optionLabel="name" [filter]="false"
                                                filterBy="name" [showClear]="false"
                                                placeholder="Monthly delivery volume" [required]="true"
                                                [class.is-invalid]="monthlyVolumeList.invalid && (monthlyVolumeList.dirty || monthlyVolumeList.touched)">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="country-item country-item-value"
                                                        *ngIf="selectedMonthlyVolume">
                                                        <div>{{selectedMonthlyVolume.name}}</div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-country pTemplate="item">
                                                    <div class="country-item">
                                                        <div>{{country.name}}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <div *ngIf="monthlyVolumeList.invalid && (monthlyVolumeList.dirty || monthlyVolumeList.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="monthlyVolumeList.errors?.['required']">
                                                    Please fill out this field.
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="nav_detail">
                                <div class="custom_sign_up_btn">
                                    <div class="content_btn_section px-5">
                                        <div class="flex justify-content-center w-100 my-2">
                                            <div [hidden]="!loginBtn" class="btn_loader"></div>
                                        </div>
                                        <button [hidden]="loginBtn" pButton pRipple
                                            class="nav_content_btn nav_content_btn2 "> Create
                                            account<i class="pi pi-arrow-right" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class='ripple-background'>
        <div class='circle xxlarge shade1'></div>
        <div class='circle xlarge shade2'></div>
        <div class='circle large shade3'></div>
        <div class='circle mediun shade4'></div>
        <div class='circle small shade5'></div>
    </div>

</section>