import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { slideInOutAnimation } from 'src/app/Animations';
import { ApiCallService } from 'src/app/Services/api-call.service';
import { DataShareService } from 'src/app/Services/data-share.service';
import { GlobalService } from 'src/app/Services/global.service';
import { emailValidator } from '../../Directive/email-validator.directive';

interface IUser {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' },
})
export class LoginComponent implements OnInit {
  user: IUser;
  loginForm!: FormGroup;
  checked: boolean = false;
  userPwd: string = '';
  userEmail: string = '';

  constructor(
    public Global: GlobalService,
    private router: Router,
    private callMethod: ApiCallService,
    private toaster: MessageService,
    private dataShareService: DataShareService
  ) {
    this.user = {} as IUser;
  }

  private subscription: Subscription = new Subscription();
  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        emailValidator(),
      ]),
      password: new FormControl('', [Validators.required]),
    });

    let userEmailId = (this.Global.getLocalStorage({ key: 'userEmailId' }) ? this.Global.getLocalStorage({ key: 'userEmailId' }) : '');
    let userPassword = (this.Global.getLocalStorage({ key: 'userPassword' }) ? this.Global.getLocalStorage({ key: 'userPassword' }) : '');
    let JwtToken = (this.Global.getLocalStorage({ key: 'JwtToken' }) ? this.Global.getLocalStorage({ key: 'JwtToken' }) : '');

    if (userEmailId != undefined && userEmailId != '' && userPassword != undefined && userPassword != '' && JwtToken != undefined && JwtToken != '') {
      this.loginForm.get('email')?.setValue(userEmailId);
      this.loginForm.get('password')?.setValue(userPassword);
      this.checked = true;
      let merge = userEmailId + userPassword;

      this.Global.setHeaderEncryptData(merge);
      this.loginBtn = true;

      let requestObj = {
        email: userEmailId,
        password: userPassword,
      };

      setTimeout(() => {
        this.signInCall(requestObj);
      }, 2000);
    }
  }

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  isLoginValid() {
    if (this.loginForm.invalid) {
      for (const control of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[control].markAsTouched();
      }
      return;
    } else {
      this.user = this.loginForm.value;
      let merge = this.user.email + this.user.password;
      this.Global.setHeaderEncryptData(merge);
      this.loginBtn = true;
      let reqObj = {
        email: this.user.email,
        password: this.user.password,
      };
      this.signInCall(reqObj);
    }
  }
  loginBtn: boolean = false;

  signInCall(reqObj: any) {
    this.callMethod.signInCall(reqObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      console.log("********************************** signInCall() **********************************", result);
      if (result.status) {
        this.Global.setLocalStorage({
          'key': 'JwtToken',
          value: result.token,
        });
        this.Global.setLocalStorage({
          'key': 'userId',
          value: result.data.userId,
        });
        this.Global.setLocalStorage({
          'key': 'isFirstLogin',
          value: result.data.isFirstLogin,
        });
        this.Global.setLocalStorage({
          key: 'parentUser',
          value: result.data.parentUser,
        });
        // localStorage.setItem('perentUserID',result.data.parentUser)
        this.Global.setLocalStorage({
          'key': 'type',
          value: result.data.type,
        });

        this.Global.userDetails.JwtToken = result.token
        this.Global.userDetails.parentUser = result.data.parentUser
        this.Global.settingInfo.timeZone = result.data.timezone;
        this.Global.userDetails.userId = result.data.userId
        this.Global.userDetails.phone = result.data.phone
        this.Global.userDetails.fullName = result.data.fullName
        this.Global.userDetails.userCompanyName = result.data.companyName
        this.Global.userDetails.email = result.data.email
        this.Global.userDetails.type = result.data.type
        this.Global.userDetails.roles = result.data.roles
        this.Global.userDetails.isFirstLogin = result.data.isFirstLogin
        if (result.data.isFirstLogin) {
          this.router.navigateByUrl('reset-password');
        } else {
          this.router.navigate(['dashboard/manage']);
        }
      } else {
        this.Global.removeLocalStorage({ 'key': 'userEmailId' });
        this.Global.removeLocalStorage({ 'key': 'userPassword' });
        this.Global.removeLocalStorage({ 'key': 'JwtToken' });
        this.loginBtn = false;
        if (!result.status) {
          this.toaster.add({
            severity: 'error',
            summary: 'Error',
            detail: result.message,
          });
        }
      }
    });
  }

  gotoForgetPassword(data: any) {
    console.log("getUserEmail");
    if (this.email.value.trim().length > 0) {
      const encryptedUserEmail = this.Global.encryptData(
        this.email.value.trim()
      );
      this.router.navigate(["/forget-password"], {
        queryParams: { resetEmail: encryptedUserEmail },
      });
      return;
    }
    this.router.navigate(["/forget-password"]);
  }

  rememberMe(event: any) {
    this.user = this.loginForm.value;
    if (event.target.checked) {
      this.checked = true;
      this.Global.setLocalStorage({
        'key': 'userEmailId',
        value: this.user.email,
      });
      this.Global.setLocalStorage({
        'key': 'userPassword',
        value: this.user.password,
      });
    } else {
      this.checked = false;
      this.Global.removeLocalStorage({ 'key': 'userEmailId' });
      this.Global.removeLocalStorage({ 'key': 'userPassword' });
    }
  }
}
