import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable()
export class ErrorLoggingService {

  logError(endpoint: string, request: any, error: any): void {
    // Create an error log object
    const errorLog = {
      endpoint,
      request,
      error
    };

    // Convert the error log object to JSON string
    const errorJson = JSON.stringify(errorLog);

    // Save the error log as a JSON file
    const file = new Blob([errorJson], { type: 'application/json' });
    saveAs(file, 'errorlog.json');
  }
}