import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService {
  private submittingSubject = new BehaviorSubject<boolean>(false);
  submitting$ = this.submittingSubject.asObservable();

  setSubmitting(isSubmitting: boolean) {
    this.submittingSubject.next(isSubmitting);
  }

  isSubmitting(): boolean {
    return this.submittingSubject.getValue();
  }
}
